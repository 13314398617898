<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{
        $router.currentRoute.value.name == "UpdatePodcastAdminShow"
          ? "Update"
          : "Add"
      }}
      Podcast Show
    </h2>
    <form @submit.prevent="handleSubmit">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Show Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="podcaster">Podcaster</label>
              <Dropdown
                id="podcaster"
                v-model="form.podcaster_id"
                :options="podcasters"
                optionLabel="name"
                optionValue="podcaster_id"
                :filter="true"
                filterBy="name,email"
                placeholder="Select a podcaster"
                :class="{ 'p-invalid': submitted && !form.podcaster_id }"
              >
                <template #option="slotProps">
                  {{ slotProps.option.name }} ({{ slotProps.option.email }})
                </template>
              </Dropdown>
              <small v-if="submitted && !form.podcaster_id" class="p-error">
                Podcaster is required
              </small>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="rss_url">RSS link </label>
              <InputText
                id="rss_url"
                v-model="form.rss_url"
                :class="{ 'p-invalid': submitted && !form.rss_url }"
                @input="checkRssChange"
              ></InputText>
              <small v-if="submitted && !form.rss_url" class="p-error"
                >RSS link is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="show_title">Title </label>
              <InputText id="show_title" v-model="form.show_title"></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="author">Author </label>
              <InputText id="author" v-model="form.author"></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="categories">Categories </label>
              <InputText
                id="categories"
                v-model="form.categories"
                :class="{ 'p-invalid': submitted && !form.rss_url }"
              ></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_month">Avg Downloads Per Month </label>
              <InputText
                id="avg_per_month"
                v-model="form.avg_per_month"
                :class="{ 'p-invalid': submitted && !form.avg_per_month }"
              ></InputText>
              <small v-if="submitted && !form.avg_per_month" class="p-error"
                >Avg download per month is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="avg_per_episode">Avg Downloads Per Episode </label>
              <InputText
                id="avg_per_episode"
                v-model="form.avg_per_episode"
                :class="{ 'p-invalid': submitted && !form.avg_per_episode }"
              ></InputText>
              <small v-if="submitted && !form.avg_per_episode" class="p-error"
                >Avg download per episode is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="spotify_link">Spotify link </label>
              <InputText
                id="spotify_link"
                v-model="form.spotify_link"
              ></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="itunes_link">iTunes link </label>
              <InputText
                id="itunes_link"
                v-model="form.itunes_link"
              ></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="hosted">Hosted </label>
              <Dropdown
                id="hosted"
                v-model="form.hosted"
                :options="hostedOptions"
                optionLabel="name"
                optionValue="value"
                placeholder="Select Hosted Type"
                :class="{ 'p-invalid': submitted && !form.hosted }"
              ></Dropdown>
              <small v-if="submitted && !form.hosted" class="p-error"
                >Hosted type is required</small
              >
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="hosted">Do not start campaigns </label>
              <SelectButton
                id="hosted"
                v-model="form.no_campaign"
                :options="noCampaignOptions"
                optionLabel="name"
                optionValue="value"
              ></SelectButton>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Promotion Settings</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="promotion_pub_date_back_limit"
                >Publication Date Back Limit</label
              >
              <InputText
                id="promotion_pub_date_back_limit"
                type="number"
                v-model="form.promotion_pub_date_back_limit"
              ></InputText>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-1">
              <label for="promotion_last_episodes_limit"
                >Last Episodes Limit</label
              >
              <InputText
                id="promotion_last_episodes_limit"
                type="number"
                v-model="form.promotion_last_episodes_limit"
              ></InputText>
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import { ref, computed, onMounted } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts"; // Corrected import statement
import SelectButton from "primevue/selectbutton";

const router = useRouter();
const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const submitted = ref(false);
const form = ref({
  podcaster_id: "",
  rss_url: "",
  show_title: "",
  author: "",
  categories: "",
  spotify_link: "",
  itunes_link: "",
  avg_per_episode: 0,
  avg_per_month: 0,
  promotion_pub_date_back_limit: 0,
  promotion_last_episodes_limit: 0,
  hosted: "",
  no_campaign: false,
});

const hostedOptions = ref([
  { name: "Self", value: "self" },
  { name: "Simplecast Jamx", value: "simplecast_jx" },
  { name: "Simplecast Pulsar", value: "simplecast_ps" },
  { name: "Omny", value: "omny" },
  { name: "Soundstack", value: "soundstack" },
  { name: "Libsyn", value: "libsyn" },
  { name: "Spreaker", value: "spreaker" },
  { name: "Demo", value: "demo" },
]);

const noCampaignOptions = ref([
  { name: "Start", value: false },
  { name: "Do not start", value: true },
]);

const showId = router.currentRoute.value.params.showId;
const token = computed(() =>
  localStorage.getItem(
    router.currentRoute.value.path.includes("podcast-admin/add-show")
      ? "podcastAdminToken"
      : "podcasterToken"
  )
);
const podcasters = ref([]);
const originalRssUrl = ref("");
const isRssChanged = ref(false);

const fetchPodcasters = async () => {
  if (router.currentRoute.value.path.includes("podcast-admin/add-show")) {
    try {
      store.commit("setLoading", true);
      const response = await axios.get(API_BASE_URL + "/admin/podcasters", {
        headers: { Authorization: `Bearer ${token.value}` },
      });
      console.log(response);
      if (response.data) {
        podcasters.value = response.data.map((podcaster) => ({
          podcaster_id: podcaster.podcaster_id,
          name: podcaster.name,
          email: podcaster.email,
        }));
      } else {
        throw new Error(response.data.message || "Failed to fetch podcasters");
      }
    } catch (error) {
      console.error("Error fetching podcasters:", error);
      toast.add({
        severity: "error",
        summary: "Failed to fetch podcasters",
        detail: error.message || "An unexpected error occurred",
        life: 4000,
      });
    } finally {
      store.commit("setLoading", false);
    }
  }
};

const getShowData = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      API_BASE_URL + "/podcaster/shows/" + showId,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );
    if (response.data.success) {
      const {
        podcaster_id,
        rss_url,
        show_title,
        author,
        categories,
        spotify_link,
        itunes_link,
        avg_per_episode,
        avg_per_month,
        promotion_pub_date_back_limit,
        promotion_last_episodes_limit,
        hosted,
      } = response.data.show;
      form.value = {
        ...form.value,
        podcaster_id,
        rss_url,
        show_title,
        author,
        categories,
        spotify_link,
        itunes_link,
        avg_per_episode,
        avg_per_month,
        promotion_pub_date_back_limit,
        promotion_last_episodes_limit,
        hosted,
      };
      originalRssUrl.value = rss_url;
    } else
      toast.add({
        severity: "error",
        summary: "Show data not found",
        life: 4000,
      });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Something went wrong...",
      life: 4000,
    });
  }
  store.commit("setLoading", false);
};

const checkRssChange = () => {
  isRssChanged.value = form.value.rss_url !== originalRssUrl.value;
};

const handleSubmit = () => {
  submitted.value = true;
  if (
    form.value.rss_url &&
    form.value.avg_per_episode &&
    form.value.avg_per_month &&
    form.value.hosted &&
    form.value.podcaster_id
  ) {
    if (
      isRssChanged.value &&
      router.currentRoute.value.path.includes("podcast-admin/add-show") &&
      showId
    ) {
      confirm.require({
        message:
          "You changed the RSS feed URL. This will cause migration of the show and delete all previous episodes. Are you sure?",
        header: "Confirm RSS Change",
        icon: "pi pi-exclamation-triangle",
        accept: () => submitForm(true),
        reject: () => {
          submitted.value = false;
        },
      });
    } else {
      submitForm(false);
    }
  }
};

const submitForm = async (rssChanged) => {
  try {
    store.commit("setLoading", true);

    const payload = {
      ...form.value,
      rss_changed: rssChanged,
    };

    if (showId) {
      payload.show_id = showId;
    }

    const response = await axios.post(
      API_BASE_URL + "/podcaster/add-show",
      payload,
      {
        headers: {
          Authorization: `Bearer ${token.value}`,
        },
      }
    );

    if (response.data?.success) {
      // Clear form after successful submission
      form.value = {
        podcaster_id: "",
        rss_url: "",
        spotify_link: "",
        itunes_link: "",
        show_title: "",
        author: "",
        categories: "",
        avg_per_episode: 0,
        avg_per_month: 0,
        promotion_pub_date_back_limit: 0,
        promotion_last_episodes_limit: 0,
        hosted: "",
        no_campaign: false,
      };

      submitted.value = false;
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Show ${showId ? "updated" : "added"} successfully!`,
        life: 4000,
      });
      router.replace({ name: "PodcastAdminShowsList" });
    } else {
      toast.add({
        severity: "error",
        detail: `Something went wrong...`,
        life: 4000,
      });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      detail: `Something went wrong...`,
      life: 4000,
    });
    console.log(error);
  } finally {
    store.commit("setLoading", false);
  }
};

onMounted(() => {
  fetchPodcasters();
  if (showId) {
    form.value.show_id = showId;
    getShowData();
  }
});
</script>
