<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Bulk Change Campaigns</h2>
    <Card class="mb-3">
      <template #content>
        <div class="grid">
          <div class="col-12 md:col-6 flex flex-column gap-1">
            <label for="bulk_mode">Type of bulk change</label>
            <Dropdown
              input-id="bulk_mode"
              v-model="bulk_mode"
              :options="bulk_modes"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a bulk
            change mode"
              :class="{ 'p-invalid': submitted && !bulk_mode }"
              @change="handleBulkModeSwitch"
            />
            <small v-if="submitted && !bulk_mode" class="p-error">
              Please select a bulk change mode
            </small>
          </div>
          <div
            v-if="bulk_mode === 'network_campaigns_intervals'"
            class="col-12 md:col-6 flex flex-column gap-1"
          >
            <label for="network_campaigns">Increase Interval for all:</label>
            <Dropdown
              input-id="network_campaigns"
              v-model="usOrNoSelected"
              :options="us_or_non_us"
              optionLabel="label"
              optionValue="value"
              placeholder="Select a type of campaign"
              :class="{ 'p-invalid': submitted && !usOrNoSelected }"
            />
            <small v-if="submitted && !usOrNoSelected" class="p-error">
              Please select a type of campaign
            </small>
          </div>
          <div
            v-if="usOrNoSelected"
            class="col-12 md:col-6 flex flex-column gap-1"
          >
            <label for="select_shows">Exclude Shows:</label>
            <MultiSelect
              input-id="select_shows"
              v-model="excludeShows"
              :options="store.getters.shows"
              optionLabel="show_title"
              optionValue="show_id"
              placeholder="Exclude shows"
              display="chip"
              auto-filter-focus
              filter
              :filter-fields="['show_title']"
            />
          </div>
          <div
            v-if="
              bulk_mode === 'spotify_campaigns_intervals' ||
              bulk_mode === 'promotion_campaigns_intervals'
            "
            class="col-12 md:col-6 flex flex-column gap-1"
          >
            <label for="select_hosted">Select Hosting Platform:</label>
            <Dropdown
              input-id="select_hosted"
              v-model="selectedHosted"
              :options="hostedOptions"
              placeholder="Select hosting platform"
              @change="selectedShows = []"
            />
          </div>
          <div
            v-if="
              bulk_mode === 'spotify_campaigns_intervals' ||
              bulk_mode === 'promotion_campaigns_intervals'
            "
            class="col-12 md:col-6 flex flex-column gap-1"
          >
            <label for="select_shows">Select Shows:</label>
            <MultiSelect
              input-id="select_shows"
              v-model="selectedShows"
              :options="shows"
              optionLabel="show_title"
              optionValue="show_id"
              placeholder="Select shows"
              display="chip"
              auto-filter-focus
              filter
              :filter-fields="['show_title']"
              :class="{ 'p-invalid': submitted && !selectedShows.length }"
            />
            <small v-if="submitted && !selectedShows.length" class="p-error">
              Please select at least one show
            </small>
          </div>
          <div
            v-if="selectedShows?.length || usOrNoSelected"
            class="col-12 md:col-6 flex flex-column gap-1"
          >
            <label for="percentage"
              >Increase{{
                bulk_mode === "network_campaigns_intervals" ? "" : "/Decrease"
              }}
              Interval by percentage:</label
            >
            <InputGroup>
              <SelectButton
                v-if="bulk_mode !== 'network_campaigns_intervals'"
                v-model="increaseOrDecrease"
                :options="increaseOrDecreaseOptions"
                option-label="label"
                option-value="value"
              />
              <InputNumber
                input-id="percentage"
                :max-fraction-digits="2"
                prefix="%"
                v-model="percentage"
                placeholder="Enter percentage number"
                :min="0"
              />
            </InputGroup>
            <small
              v-if="
                submitted &&
                (!realPercent || realPercent === 0 || realPercent < -100)
              "
              class="p-error"
            >
              Please enter a valid percentage
            </small>
          </div>
        </div>
      </template>
    </Card>
    <div class="flex justify-content-end gap-2">
      <Button
        label="Cancel"
        @click="router.go(-1)"
        outlined
        class="p-button-danger"
      />
      <Button
        label="Submit"
        @click="submitBulkChange"
        class="p-button-success"
      />
    </div>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import MultiSelect from "primevue/multiselect";
import { onBeforeMount, ref, watch } from "vue";
import axios from "axios";
import { useStore } from "vuex";
import InputNumber from "primevue/inputnumber";
import { computed } from "vue";
import Button from "primevue/button";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import { useRouter } from "vue-router";
import InputGroup from "primevue/inputgroup";
import SelectButton from "primevue/selectbutton";

const toast = useToast();

const router = useRouter();

const store = useStore();

const token = localStorage.getItem("podcastAdminToken");

const bulk_mode = ref(null);

const usOrNoSelected = ref(null);

const selectedShows = ref([]);

const excludeShows = ref([]);

const selectedHosted = ref("All");

const percentage = ref(0);

const realPercent = ref(0);

const submitted = ref(false);

const increaseOrDecrease = ref(1);

const increaseOrDecreaseOptions = [
  { label: "Increase", value: 1 },
  { label: "Decrease", value: -1 },
];

const shows = computed(() => {
  return store.getters.shows.filter(
    (show) =>
      selectedHosted.value === "All" || show.hosted === selectedHosted.value
  );
});

const hostedOptions = computed(() => {
  const hostedValues = new Set(["All"]);
  store.getters.shows.forEach((show) => {
    if (show.hosted) {
      hostedValues.add(show.hosted);
    }
  });
  return Array.from(hostedValues);
});

const selectedCampaigns = computed(() => {
  if (bulk_mode.value === "network_campaigns_intervals") {
    return store.getters.campaigns
      .filter(
        (campaign) =>
          (usOrNoSelected.value === "us_campaigns"
            ? campaign.geos_wl?.includes("us")
            : !campaign.geos_wl?.includes("us")) &&
          !excludeShows.value.includes(campaign.show_id)
      )
      .map((campaign) => campaign.campaign_id);
  } else {
    return store.getters.campaigns
      .filter((campaign) => selectedShows.value.includes(campaign.show_id))
      .map((campaign) => campaign.campaign_id);
  }
});

const bulk_modes = [
  {
    label: "Intervals of Network Campaigns",
    value: "network_campaigns_intervals",
  },
  {
    label: "Intervals of Spotify Campaigns",
    value: "spotify_campaigns_intervals",
  },
  {
    label: "Intervals of Promotion Campaigns",
    value: "promotion_campaigns_intervals",
  },
];

const us_or_non_us = [
  {
    label: "US Campaigns under Network",
    value: "us_campaigns",
  },
  {
    label: "Non-US Campaigns under Network",
    value: "non_us_campaigns",
  },
];

const handleBulkModeSwitch = () => {
  usOrNoSelected.value = null;
  selectedShows.value = [];
  percentage.value = 0;
};

const fetchCampaigns = async () => {
  if (!store.getters.campaigns || store.getters.campaigns.length == 0) {
    store.dispatch("loadCampaigns", { token });
  }
};

const fetchShows = () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

const submitBulkChange = async () => {
  submitted.value = true;
  realPercent.value = increaseOrDecrease.value * percentage.value;
  try {
    if (!bulk_mode.value) return;
    if (realPercent.value < -100 || realPercent.value === 0) return;
    if (bulk_mode.value === "network_campaigns_intervals") {
      if (!usOrNoSelected.value) return;
    } else {
      if (!selectedShows.value.length) return;
    }
    store.commit("setLoading", true);

    const response = await axios.post(
      `${API_BASE_URL}/campaigns/bulk-change`,
      {
        interval_percentage_to_apply: realPercent.value,
        campaignIds: selectedCampaigns.value,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: response.data.data.message,
        life: 6000,
      });
      router.go(-1);
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: response.data.message,
        life: 4000,
      });
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "An error occurred while processing your request",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

onBeforeMount(() => {
  fetchCampaigns();
  fetchShows();
});
</script>
