<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ router.currentRoute.value.params.groupId ? "Update" : "Add" }} Group
    </h2>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Group Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="groupName">Group Name</label>
              <InputText
                id="groupName"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
                placeholder="e.g. My Group"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="email">Email</label>
              <InputText
                id="email"
                v-model="form.email"
                :class="{
                  'p-invalid': submitted && (!form.email || !emailValid),
                }"
                placeholder="e.g. group@example.com"
                type="email"
                autocomplete="new-email"
              />
              <small v-if="submitted && !emailValid" class="p-error"
                >Invalid email format</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="password">Password</label>
              <InputText
                id="password"
                v-model="form.password"
                autocomplete="new-password"
                type="password"
                :class="{
                  'p-invalid': submitted && !passwordValid,
                }"
                placeholder="Password"
              />
              <small v-if="submitted && !passwordValid" class="p-error"
                >Password must be atleast 8 chars, with upper-case and
                lower-case letters</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="display_id">Display ID</label>
              <InputText id="display_id" v-model="form.display_id" readonly />
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="company_domain">Company Domain: </label>
              <InputText
                id="company_domain"
                v-model="form.company_domain"
                placeholder="Company Domain"
                :class="{
                  'p-invalid': submitted && !domainValid,
                }"
              />
              <small v-if="submitted && !domainValid" class="p-error"
                >A valid domain is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="seller_type">Seller Type:</label>
              <Dropdown
                id="seller_type"
                v-model="form.seller_type"
                :options="sellerTypes"
                placeholder="Select Type"
                optionLabel="label"
                optionValue="value"
                :class="{
                  'p-invalid': submitted && !form.seller_type,
                }"
              />
              <small v-if="submitted && !form.seller_type" class="p-error"
                >Seller type is required</small
              >
            </div>
            <div v-if="isAdmin" class="flex flex-column gap-1 col-12 md:col-6">
              <label for="acc_owners">Account Manager</label>
              <Dropdown
                input-id="acc_owners"
                v-model="form.group_account_manager"
                :options="accountOwners"
                option-label="name"
                option-value="id"
                placeholder="Select Owner"
                show-clear
              />
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Contact Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="company_name">Company Name</label>
                <InputText
                  id="company_name"
                  v-model="form.company_name"
                  placeholder="Company Name"
                  :class="{
                    'p-invalid': submitted && !form.company_name,
                  }"
                />
                <small v-if="submitted && !form.company_name" class="p-error"
                  >Company name is required</small
                >
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address">Company Address</label>
                <InputText
                  id="company_address"
                  v-model="form.company_address"
                  placeholder="Company Address"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_country_of_registration"
                  >Company Country</label
                >
                <InputText
                  id="company_country_of_registration"
                  v-model="form.company_country_of_registration"
                  placeholder="Company Country"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_city">Company City</label>
                <InputText
                  id="company_address_city"
                  v-model="form.company_address_city"
                  placeholder="Company City"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_zip_code">Company ZipCode</label>
                <InputText
                  id="company_address_zip_code"
                  v-model="form.company_address_zip_code"
                  placeholder="Company ZipCode"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_name">Contact Person Name</label>
                <InputText
                  id="contact_person_name"
                  v-model="form.contact_person_name"
                  placeholder="Contact Person Name"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_title">Contact Person Title</label>
                <InputText
                  id="contact_person_title"
                  v-model="form.contact_person_title"
                  placeholder="Contact Person Title"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_email">Contact Person Email</label>
                <InputText
                  id="contact_person_email"
                  v-model="form.contact_person_email"
                  placeholder="Contact Person Email"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_phone_number"
                  >Contact Person Phone</label
                >
                <InputText
                  id="contact_person_phone_number"
                  v-model="form.contact_person_phone_number"
                  placeholder="Contact Person Phone"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_mobile_number"
                  >Contact Person Mobile</label
                >
                <InputText
                  id="contact_person_mobile_number"
                  v-model="form.contact_person_mobile_number"
                  placeholder="Contact Person Mobile"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="finance_contact_name">Finance Contact</label>
                <InputText
                  id="finance_contact_name"
                  v-model="form.finance_contact_name"
                  placeholder="Finance Contact"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_email">Accounting Email</label>
                <InputText
                  id="accounting_dept_email"
                  v-model="form.accounting_dept_email"
                  placeholder="Accounting Email"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_phone_number"
                  >Accounting Phone Number</label
                >
                <InputText
                  id="accounting_dept_phone_number"
                  v-model="form.accounting_dept_phone_number"
                  placeholder="Accounting Phone Number"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="payment_term">Payment Term</label>
                <InputText
                  id="payment_term"
                  v-model="form.payment_term"
                  placeholder="Payment Term"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="show_gross_profit">Show Gross Profit</label>
                <SelectButton
                  id="show_gross_profit"
                  v-model="form.show_gross_profit"
                  :options="BoolTypes"
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="can_add_sellers">Can Add Sellers</label>
                <SelectButton
                  id="can_add_sellers"
                  v-model="form.can_add_sellers"
                  :options="BoolTypes"
                  optionLabel="label"
                  optionValue="value"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="show_cpm">Show CPM</label>
                <SelectButton
                  id="show_cpm"
                  v-model="form.show_cpm"
                  :options="BoolTypes"
                  optionLabel="label"
                  optionValue="value"
                />
              </div>

              <template v-if="form.can_add_sellers">
                <div class="flex flex-column gap-1 w-full">
                  <label for="default_contract_type"
                    >Default Contract Type:</label
                  >
                  <Dropdown
                    id="default_contract_type"
                    v-model="form.default_contract_type"
                    :options="contractTypes"
                    placeholder="Select Type"
                    optionLabel="label"
                    optionValue="value"
                    :class="{
                      'p-invalid': submitted && !form.default_contract_type,
                    }"
                    @change="
                      () => {
                        form.default_contract_value = 0;
                      }
                    "
                  />
                  <small
                    v-if="submitted && !form.default_contract_type"
                    class="p-error"
                    >Contract type is required</small
                  >
                </div>
                <div class="flex flex-column gap-1 w-full">
                  <template v-if="form.default_contract_type === 'cpm'">
                    <label
                      v-if="form.default_contract_type?.length"
                      for="default_contract_value"
                      >{{ contractValueLabel }}:</label
                    >
                    <InputText
                      id="default_contract_value"
                      v-model.number="form.default_contract_value"
                      :class="{
                        'p-invalid': submitted && !form.default_contract_value,
                      }"
                    />
                    <small
                      v-if="submitted && !form.default_contract_value"
                      class="p-error"
                      >Contract value is required</small
                    >
                  </template>
                  <template
                    v-else-if="form.default_contract_type === 'revshare'"
                  >
                    <label
                      v-if="form.default_contract_type?.length"
                      for="default_contract_value"
                      >{{ contractValueLabel }}:</label
                    >
                    <input
                      id="default_contract-value"
                      type="range"
                      min="5"
                      max="75"
                      step="5"
                      v-model="form.default_contract_value"
                    />
                    <span style="text-align: left; width: 20%"
                      >{{ form.default_contract_value }}%</span
                    >
                    <small
                      v-if="submitted && !form.default_contract_value"
                      class="p-error"
                      >Contract value is required</small
                    >
                  </template>
                </div>
              </template>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3" v-if="publisherShows?.length">
        <template #title>
          <div class="text-xl">Promotion Campaigns</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="publisher_shows">Select Shows</label>
              <MultiSelect
                input-id="publisher_shows"
                :options="publisherShows"
                v-model="selectedPublisherShows"
                display="chip"
                placeholder="Select shows"
              >
                <template #option="slotProps">
                  {{
                    `${slotProps.option.show_title || "Show ID:"} [${
                      slotProps.option.show_id
                    }]`
                  }}
                </template>
              </MultiSelect>
            </div>
            <div
              v-if="selectedPublisherShows?.length"
              class="flex flex-column gap-1 col-12 md:col-6"
            >
              <label for="publisher_shows_start_promotion"
                >Create promotion campaigns</label
              >
              <Button
                label="Create Promotion Campaigns"
                @click="createPromotionCampaigns"
              />
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

import Button from "primevue/button";
import SelectButton from "primevue/selectbutton";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import MultiSelect from "primevue/multiselect";

export default {
  components: {
    InputText,
    Button,
    Card,
    Dropdown,
    SelectButton,
    MultiSelect,
  },

  setup() {
    const store = useStore();
    const toast = useToast();

    const submitted = ref(false);

    const emailValid = ref(true);
    const domainValid = ref(true);
    const passwordValid = ref(true);

    const accountOwners = ref([]);

    const publisherShows = ref([]);

    const isAdmin = store.getters.isAdminLoggedIn;

    const token =
      localStorage.getItem("adminToken") || localStorage.getItem("token");

    const contractTypes = [
      { label: "Per Load", value: "cpm" },
      { label: "Revenue Share", value: "revshare" },
    ];

    const BoolTypes = [
      { label: "Disable", value: false },
      { label: "Allow", value: true },
    ];

    const sellerTypes = [
      { label: "Publisher", value: "PUBLISHER" },
      { label: "Intermediary", value: "INTERMEDIARY" },
      { label: "Both", value: "BOTH" },
    ];

    const form = ref({
      name: "",
      email: "",
      password: "",
      company_name: "",
      company_address: "",
      company_country_of_registration: "",
      company_address_city: "",
      company_address_zip_code: "",
      contact_person_name: "",
      contact_person_title: "",
      contact_person_email: "",
      contact_person_phone_number: "",
      contact_person_mobile_number: "",
      finance_contact_name: "",
      accounting_dept_email: "",
      accounting_dept_phone_number: "",
      payment_term: "",
      default_contract_type: contractTypes[0].value,
      default_contract_value: 0,
      show_gross_profit: BoolTypes[0].value,
      can_add_sellers: BoolTypes[0].value,
      company_domain: "",
      seller_type: "PUBLISHER",
      display_id: "",
      group_account_manager: null,
      show_cpm: BoolTypes[0].value,
    });

    const selectedPublisherShows = ref([]);

    const router = useRouter();

    const groupId = router.currentRoute.value.params.groupId;

    const contractValueLabel = computed(() => {
      if (form.value.default_contract_type === "cpm") {
        return "CPM Rate";
      } else {
        return "Share Percent";
      }
    });

    const fetchAccountManagers = async () => {
      if (!isAdmin) return;
      store.commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/account-managers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          },
        });

        if (response.data.success) {
          accountOwners.value = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching account managers:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error fetching group account managers",
          life: 4000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchPublisherShows = async () => {
      if (!isAdmin || !groupId) return;
      try {
        store.commit("setLoading", true);
        const response = await axios.get(
          `${API_BASE_URL}/publisher-shows/${groupId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          }
        );

        if (response.data.success) {
          publisherShows.value = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching publisher shows:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error fetching group publisher shows",
          life: 4000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const createPromotionCampaigns = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.post(
          `${API_BASE_URL}/admin/promotion-campaign`,
          selectedPublisherShows.value,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          }
        );

        if (response.data.success) {
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Promotion campaigns created successfully",
            life: 4000,
          });
        }
      } catch (error) {
        console.error("Error creating promotion campaigns:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error creating promotion campaigns",
          life: 4000,
        });
      } finally {
        selectedPublisherShows.value = [];
        store.commit("setLoading", false);
      }
    };

    const fetchSellerGroup = async () => {
      try {
        store.commit("setLoading", true);
        if (!groupId) return;

        const response = await axios.get(
          API_BASE_URL + "/seller-group/" + groupId,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          const sellerGroup = response.data.sellerGroup;

          form.value = sellerGroup;

          if (form.value.default_contract_type == "revshare")
            form.value.default_contract_value *= 100;
        }
      } catch (error) {
        console.error("Error fetching seller group:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const validateEmail = () => {
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emailValid.value = re.test(String(form.value.email).toLowerCase());
    };

    const validateDomain = () => {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
      domainValid.value = re.test(
        String(form.value.company_domain).toLowerCase()
      );
    };

    const validatePassword = () => {
      passwordValid.value = !(
        !form.value.password ||
        form.value.password.length < 8 ||
        !(
          /[a-z]/.test(form.value.password) && /[A-Z]/.test(form.value.password)
        )
      );
    };

    const submitForm = async () => {
      submitted.value = true;
      validateEmail();
      validateDomain();

      if (!groupId) validatePassword();
      else {
        passwordValid.value = true;
        form.value.groupId = groupId;
      }

      if (
        form.value.name &&
        form.value.email &&
        form.value.company_name &&
        form.value.company_domain &&
        emailValid.value &&
        passwordValid.value &&
        domainValid.value
      ) {
        if (
          form.value.can_add_sellers &&
          (!form.value.default_contract_type?.length ||
            form.value.default_contract_value == 0 ||
            !form.value.default_contract_value)
        )
          return;
        try {
          store.commit("setLoading", true);

          let response;

          response = await axios.post(
            `${API_BASE_URL}/seller-group`,
            form.value,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data?.success) {
            toast.add({
              severity: "success",
              summary: "Success",
              detail: `Group ${groupId ? "updated" : "added"} successfully!`,
              life: 4000,
            });
            router.go(-1);
            // Show a success message, or redirect to another page, etc.
          } else {
            // Handle unsuccessful submission
            toast.add({
              severity: "error",
              summary: "Error",
              detail: `Something went wrong...`,
              life: 4000,
            });
          }
        } catch (error) {
          // Handle errors
          toast.add({
            severity: "error",
            summary: "Error",
            detail: `Something went wrong...`,
            life: 4000,
          });
          console.log(error);
        } finally {
          store.commit("setLoading", false);
        }
      }
    };
    onMounted(() => {
      fetchSellerGroup();
      fetchAccountManagers();
      fetchPublisherShows();
    });

    return {
      form,
      router,
      submitForm,
      fetchSellerGroup,
      router,
      submitted,
      emailValid,
      validatePassword,
      passwordValid,
      contractValueLabel,
      contractTypes,
      BoolTypes,
      sellerTypes,
      accountOwners,
      isAdmin,
      groupId,
      publisherShows,
      selectedPublisherShows,
      createPromotionCampaigns,
    };
  },
};
</script>
