<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Scheduled Reports</h2>
    <Card>
      <template #header>
        <div class="flex flex-column sm:flex-row gap-3 sm:gap-2 p-3 pb-0">
          <Button size="small" @click="goAddSchedule">New Schedule</Button>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="scheduledReports"
          dataKey="id"
          :paginator="true"
          :rows="10"
        >
          <template #empty>
            <div class="p-4 text-center text-500">
              No scheduled reports found
            </div>
          </template>
          <Column field="name" header="Schedule Name"></Column>
          <Column field="schedule" header="Frequency">
            <template #body="slotProps">
              <span v-if="slotProps.data.schedule === '0 10 * * *'">Daily</span>
              <span v-else-if="slotProps.data.schedule === '0 10 * * 1'"
                >Weekly</span
              >
              <span v-else-if="slotProps.data.schedule === '0 10 1 * *'"
                >Monthly</span
              >
            </template>
          </Column>
          <Column field="status" header="Status"></Column>
          <Column header="Actions">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button
                  size="small"
                  icon="pi pi-play"
                  :label="
                    slotProps.data.status === 'active' ? 'Running' : 'Run'
                  "
                  :disabled="slotProps.data.status === 'active'"
                  severity="info"
                  @click="confirmRunScheduledReport(slotProps.data.id)"
                ></Button>
                <Button
                  v-if="slotProps.data.status !== 'active'"
                  size="small"
                  icon="pi pi-trash"
                  label="Delete"
                  severity="danger"
                  @click="confirmDeleteScheduledReport(slotProps.data.id)"
                ></Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import Card from "primevue/card";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { onMounted } from "vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";

const router = useRouter();
const confirm = useConfirm();
const store = useStore();
const toast = useToast();

const token = localStorage.getItem("adminToken");
const scheduledReports = ref([]);

const goAddSchedule = () => {
  // Add schedule route
  router.push({ name: "AdminAddScheduledReport" });
};

const runScheduledReport = async (id) => {
  try {
    store.commit("setLoading", true);
    const response = await axios.post(
      `${API_BASE_URL}/scheduled-reports/run/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      console.log("Scheduled report run successfully");
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Scheduled report run successfully",
        life: 4000,
      });
      await fetchScheduledReports();
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to run scheduled report",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const deleteScheduledReport = async (id) => {
  try {
    store.commit("setLoading", true);
    const response = await axios.delete(
      `${API_BASE_URL}/scheduled-reports/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Scheduled report deleted successfully",
        life: 4000,
      });
      await fetchScheduledReports();
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete scheduled report",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const confirmDeleteScheduledReport = (id) => {
  confirm.require({
    message: "Are you sure you want to delete this scheduled report?",
    header: "Confirm Delete",
    icon: "pi pi-exclamation-triangle",
    accept: () => deleteScheduledReport(id),
  });
};
const confirmRunScheduledReport = (id) => {
  confirm.require({
    message: "Are you sure you want to run this scheduled report?",
    header: "Confirm Run",
    icon: "pi pi-exclamation-triangle",
    accept: () => runScheduledReport(id),
  });
};

const fetchScheduledReports = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(`${API_BASE_URL}/scheduled-reports`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.success) {
      scheduledReports.value = response.data.data;
      console.log("scheduledReports: ", scheduledReports.value);
    }
  } catch (error) {
    console.error(error);
  } finally {
    store.commit("setLoading", false);
  }
};

onMounted(() => {
  fetchScheduledReports();
});
</script>
