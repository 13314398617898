<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Schedule Report</h2>
    <form @submit.prevent="handleSubmit">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Report Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="report_name">Schedule Name</label>
              <InputText
                id="report_name"
                v-model="formData.report_name"
                :class="{
                  'p-invalid': submitted && !formData.report_name?.length,
                }"
              />
              <small
                v-if="submitted && !formData.report_name?.length"
                class="p-error"
              >
                Please provide a name for the schedule
              </small>
            </div>
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="saved_reports">Select Report</label>
              <Dropdown
                id="saved_reports"
                v-model="formData.report_data_id"
                :options="savedReports"
                option-label="name"
                option-value="id"
                :filter="true"
                filterBy="name"
                placeholder="Select a report"
                :class="{ 'p-invalid': submitted && !formData.report_data_id }"
              >
              </Dropdown>
              <small
                v-if="submitted && !formData.report_data_id"
                class="p-error"
              >
                Please select a report for schedule
              </small>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Select Recipients</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1 p-fluid"
            >
              <label for="recipients">Recipient Emails</label>
              <Chips
                input-id="recipients"
                v-model="formData.report_recipients"
                placeholder="Enter emails"
                separator=","
                :class="{
                  'p-invalid':
                    submitted && formData.report_recipients.length === 0,
                }"
              />
              <small
                v-if="submitted && formData.report_recipients.length === 0"
                class="p-error"
              >
                Please enter at least one recipient
              </small>
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Schedule</div>
        </template>
        <template #content>
          <div class="grid">
            <div
              class="col-12 md:col-6 flex flex-column justify-content-end gap-1"
            >
              <label for="choose_schedule">Choose Schedule</label>
              <Dropdown
                id="choose_schedule"
                v-model="formData.report_schedule"
                :options="scheduleOptions"
                optionLabel="label"
                optionValue="value"
              >
              </Dropdown>
            </div></div></template
      ></Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MultiSelect from "primevue/multiselect";
import { onMounted, ref } from "vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useStore } from "vuex";
import Button from "primevue/button";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import Chips from "primevue/chips";

const store = useStore();
const router = useRouter();
const toast = useToast();

const token = localStorage.getItem("adminToken");

const formData = ref({
  report_name: "",
  report_data_id: null,
  report_recipients: [],
  report_schedule: "0 10 * * *",
});

const scheduleOptions = [
  { label: "Daily", value: "0 10 * * *" },
  { label: "Weekly", value: "0 10 * * 1" },
  { label: "Monthly", value: "0 10 1 * *" },
];

const savedReports = ref([]);

const submitted = ref(false);

const fetchSavedReports = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      `${API_BASE_URL}/custom-reports/saved-reports`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    savedReports.value = response.data.reports;
  } catch (error) {
    console.error("Error fetching saved reports:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

const handleSubmit = async () => {
  try {
    submitted.value = true;

    if (
      !formData.value.report_name?.length ||
      !formData.value.report_data_id ||
      !formData.value.report_recipients.length
    ) {
      return;
    }

    store.commit("setLoading", true);

    const response = await axios.post(
      `${API_BASE_URL}/scheduled-reports`,
      formData.value,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Scheduled report created successfully",
        life: 4000,
      });
      console.log("Scheduled report created successfully");
      router.push({ name: "AdminReportSchedules" });
    }
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to create scheduled report",
      life: 4000,
    });
    console.error("Error creating scheduled report:", error);
  } finally {
    store.commit("setLoading", false);
  }
};

onMounted(() => {
  fetchSavedReports();
});
</script>
