<template>
  <div class="p-2 md:p-4 lg:p-8" :class="theme === 'light' && 'surface-ground'">
    <div class="flex justify-content-between">
      <h2 class="mt-0" style="color: var(--text-color)">Publisher Onboard</h2>
      <Button
        :icon="`pi ${theme == 'dark' ? 'pi-sun' : 'pi-moon'}`"
        @click="switchTheme"
        rounded
        class="w-2rem h-2rem p-0"
        size="small"
        aria-label="Theme"
      />
    </div>
    <form @submit.prevent="submitForm">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Media Group Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="groupName">*Group Name</label>
              <InputText
                id="groupName"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
                placeholder="e.g. My Group"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div
              v-if="onboardType === 'create'"
              class="flex flex-column gap-1 col-12 md:col-6"
            >
              <label for="email">*Email</label>
              <InputText
                id="email"
                v-model="form.email"
                :class="{
                  'p-invalid': submitted && (!form.email || !emailValid),
                }"
                placeholder="e.g. group@example.com"
                type="email"
                autocomplete="new-email"
              />
              <small v-if="submitted && !emailValid" class="p-error"
                >Invalid email format</small
              >
            </div>
            <div
              v-if="onboardType === 'create'"
              class="flex flex-column gap-1 col-12 md:col-6"
            >
              <label for="password">*Password</label>
              <InputText
                id="password"
                v-model="form.password"
                autocomplete="new-password"
                type="password"
                :class="{
                  'p-invalid': submitted && !passwordValid,
                }"
                placeholder="Password"
              />
              <small v-if="submitted && !passwordValid" class="p-error"
                >Passwords must be at least 8 characters long and include at
                least one uppercase letter.</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="company_domain">*Company Domain: </label>
              <InputText
                id="company_domain"
                v-model="form.company_domain"
                placeholder="Company Domain"
                :class="{
                  'p-invalid': submitted && !domainValid,
                }"
              />
              <small v-if="submitted && !domainValid" class="p-error"
                >A valid domain is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="seller_type">*Seller Type:</label>
              <Dropdown
                id="seller_type"
                v-model="form.seller_type"
                :options="sellerTypes"
                placeholder="Select Type"
                optionLabel="label"
                optionValue="value"
                :class="{
                  'p-invalid': submitted && !form.seller_type,
                }"
              />
              <small v-if="submitted && !form.seller_type" class="p-error"
                >Seller type is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="monetization_type">*Monetization Type: </label>
              <Dropdown
                id="monetization_type"
                v-model="form.monetization_type"
                :options="monetizationTypes"
                placeholder="Select Monetization Type"
                optionLabel="label"
                optionValue="value"
                :class="{
                  'p-invalid': submitted && !form.monetization_type,
                }"
              />
              <small v-if="submitted && !form.monetization_type" class="p-error"
                >Monetization Type is required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Monetization Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="average_us_monthly_page_view"
                >*Average US Monthly Page Views</label
              >
              <InputText
                id="average_us_monthly_page_view"
                v-model="form.average_us_monthly_page_view"
                :class="{
                  'p-invalid': submitted && !form.average_us_monthly_page_view,
                }"
                placeholder=""
              />
              <small
                v-if="submitted && !form.average_us_monthly_page_view"
                class="p-error"
                >Average US Monthly Page Views required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="top_geos">*Publisher Top Geo's</label>
              <InputText
                id="top_geos"
                v-model="form.top_geos"
                :class="{
                  'p-invalid': submitted && !form.top_geos,
                }"
                placeholder="Top Geos"
              />
              <small v-if="submitted && !form.top_geos" class="p-error"
                >Publisher Top Geo's required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <Card class="mb-3 onboard-add-podcasts-card">
        <template #title>
          <div class="flex gap-4 align-items-center">
            <div class="text-xl">Add Podcasts</div>
          </div>
        </template>
        <template #content>
          <div class="flex flex-column gap-3">
            <Fieldset v-for="(showForm, index) in podcastersForm" :key="index">
              <template #legend>
                <div class="flex gap-2 align-items-center -my-2">
                  <div>Podcast {{ index + 1 }}</div>
                  <div>
                    <i
                      class="pi pi-times text-red-500 cursor-pointer"
                      @click="removePodcastShow(index)"
                    ></i>
                  </div>
                </div>
              </template>
              <div class="grid">
                <div class="flex flex-column gap-1 col-12 md:col-6">
                  <label for="rss_url">*RSS url</label>
                  <InputText
                    id="rss_url"
                    v-model="podcastersForm[index].rss_url"
                    :class="{
                      'p-invalid': submitted && !podcastersForm[index].rss_url,
                    }"
                    placeholder="RSS url"
                  />
                  <small
                    v-if="submitted && !podcastersForm[index].rss_url"
                    class="p-error"
                    >RSS url required</small
                  >
                </div>
                <div class="flex flex-column gap-1 col-12 md:col-6">
                  <label for="avg_per_month">*Avg Downloads Per Month</label>
                  <InputText
                    id="avg_per_month"
                    v-model="podcastersForm[index].avg_per_month"
                    :class="{
                      'p-invalid':
                        submitted && !podcastersForm[index].avg_per_month,
                    }"
                    placeholder="Avg Downloads Per Month"
                  />
                  <small
                    v-if="submitted && !podcastersForm[index].avg_per_month"
                    class="p-error"
                    >Avg Downloads Per Month required</small
                  >
                </div>
                <div class="flex flex-column gap-1 col-12 md:col-6">
                  <label for="avg_per_episode">Avg Downloads Per Episode</label>
                  <InputText
                    id="avg_per_episode"
                    v-model="podcastersForm[index].avg_per_episode"
                    placeholder="Avg Downloads Per Episode"
                  />
                </div>
                <div class="flex flex-column gap-1 col-12 md:col-6">
                  <label for="spotify_link">Spotify link</label>
                  <InputText
                    id="spotify_link"
                    v-model="podcastersForm[index].spotify_link"
                    placeholder="Spotify link"
                  />
                </div>
                <div class="flex flex-column gap-1 col-12 md:col-6">
                  <label for="itunes_link">Apple Podcast link</label>
                  <InputText
                    id="itunes_link"
                    v-model="podcastersForm[index].itunes_link"
                    placeholder="Apple Podcast link"
                  />
                </div>
              </div>
            </Fieldset>
          </div>
        </template>
        <template #footer>
          <div class="flex gap-2">
            <Button
              class="flex-1 sm:flex-initial"
              outlined
              icon="pi pi-plus"
              icon-pos="right"
              label="Add a new Podcast"
              @click="addPodcastShow"
            />
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Contact Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="company_name">*Company Name</label>
                <InputText
                  id="company_name"
                  v-model="form.company_name"
                  placeholder="Company Name"
                  :class="{
                    'p-invalid': submitted && !form.company_name,
                  }"
                />
                <small v-if="submitted && !form.company_name" class="p-error"
                  >Company name is required</small
                >
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_vat_number">*Number/VAT</label>
                <InputText
                  id="company_vat_number"
                  v-model="form.company_vat_number"
                  placeholder="Number/VAT"
                  :class="{
                    'p-invalid': submitted && !form.company_vat_number,
                  }"
                />
                <small
                  v-if="submitted && !form.company_vat_number"
                  class="p-error"
                  >Number/VAT is required</small
                >
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address">Company Address</label>
                <InputText
                  id="company_address"
                  v-model="form.company_address"
                  placeholder="Company Address"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_country_of_registration"
                  >Company Country</label
                >
                <InputText
                  id="company_country_of_registration"
                  v-model="form.company_country_of_registration"
                  placeholder="Company Country"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_city">Company City</label>
                <InputText
                  id="company_address_city"
                  v-model="form.company_address_city"
                  placeholder="Company City"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="company_address_zip_code">Company ZipCode</label>
                <InputText
                  id="company_address_zip_code"
                  v-model="form.company_address_zip_code"
                  placeholder="Company ZipCode"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3 mb-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_name">Contact Person Name</label>
                <InputText
                  id="contact_person_name"
                  v-model="form.contact_person_name"
                  placeholder="Contact Person Name"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_title">Contact Person Title</label>
                <InputText
                  id="contact_person_title"
                  v-model="form.contact_person_title"
                  placeholder="Contact Person Title"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="contact_person_email">Contact Person Email</label>
                <InputText
                  id="contact_person_email"
                  v-model="form.contact_person_email"
                  placeholder="Contact Person Email"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3">
              <div class="flex flex-column gap-1 w-full">
                <label for="finance_contact_name">Finance Contact</label>
                <InputText
                  id="finance_contact_name"
                  v-model="form.finance_contact_name"
                  placeholder="Finance Contact"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_email">Accounting Email</label>
                <InputText
                  id="accounting_dept_email"
                  v-model="form.accounting_dept_email"
                  placeholder="Accounting Email"
                />
              </div>
              <div class="flex flex-column gap-1 w-full">
                <label for="accounting_dept_phone_number"
                  >Accounting Phone Number</label
                >
                <InputText
                  id="accounting_dept_phone_number"
                  v-model="form.accounting_dept_phone_number"
                  placeholder="Accounting Phone Number"
                />
              </div>
            </div>
            <div class="col-12 md:col-6 flex flex-column gap-3"></div>
          </div>
        </template>
      </Card>
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Publisher Bank Details</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_name">*Bank (Name + Code)</label>
              <InputText
                id="bank_name"
                v-model="form.bank_name"
                :class="{
                  'p-invalid': submitted && !form.bank_name,
                }"
                placeholder="Bank (Name + Code)"
              />
              <small v-if="submitted && !form.bank_name" class="p-error"
                >Bank (Name + Code) required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_branch_code">*Branch Code</label>
              <InputText
                id="bank_branch_code"
                v-model="form.bank_branch_code"
                :class="{
                  'p-invalid': submitted && !form.bank_branch_code,
                }"
                placeholder="Branch Code"
              />
              <small v-if="submitted && !form.bank_branch_code" class="p-error"
                >Branch Code required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_account_number">*Account Number</label>
              <InputText
                id="bank_account_number"
                v-model="form.bank_account_number"
                :class="{
                  'p-invalid': submitted && !form.bank_account_number,
                }"
                placeholder="Account Number"
              />
              <small
                v-if="submitted && !form.bank_account_number"
                class="p-error"
                >Account Number required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_iban">*IBAN</label>
              <InputText
                id="bank_iban"
                v-model="form.bank_iban"
                :class="{
                  'p-invalid': submitted && !form.bank_iban,
                }"
                placeholder="IBAN"
              />
              <small v-if="submitted && !form.bank_iban" class="p-error"
                >IBAN required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="bank_swift">*SWIFT</label>
              <InputText
                id="bank_swift"
                v-model="form.bank_swift"
                :class="{
                  'p-invalid': submitted && !form.bank_swift,
                }"
                placeholder="SWIFT"
              />
              <small v-if="submitted && !form.bank_swift" class="p-error"
                >SWIFT required</small
              >
            </div>
          </div>
        </template>
      </Card>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Go to Login"
          @click="router.push('/')"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import InputText from "primevue/inputtext";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { usePrimeVue } from "primevue/config";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Fieldset from "primevue/fieldset";

const store = useStore();
const toast = useToast();
const router = useRouter();
const PrimeVue = usePrimeVue();

const submitted = ref(false);
const emailValid = ref(true);
const domainValid = ref(true);
const passwordValid = ref(true);
const theme = ref("dark");
const token = router.currentRoute.value.query.token;
const onboardType = router.currentRoute.value.query.type;

const contractTypes = [
  { label: "Per Load", value: "cpm" },
  { label: "Revenue Share", value: "revshare" },
];

const BoolTypes = [
  { label: "Disable", value: false },
  { label: "Allow", value: true },
];

const sellerTypes = [
  { label: "Publisher", value: "PUBLISHER" },
  { label: "Intermediary", value: "INTERMEDIARY" },
  { label: "Both", value: "BOTH" },
];

const monetizationTypes = [
  { label: "Audio Only", value: "audio" },
  { label: "Audio & Display", value: "audio_display" },
  { label: "Audio, Display & Video", value: "audio_display_video" },
];

const form = ref({
  name: "",
  email: "",
  password: "",
  company_name: "",
  company_address: "",
  company_country_of_registration: "",
  company_address_city: "",
  company_address_zip_code: "",
  contact_person_name: "",
  contact_person_title: "",
  contact_person_email: "",
  contact_person_phone_number: "",
  finance_contact_name: "",
  accounting_dept_email: "",
  accounting_dept_phone_number: "",
  payment_term: "",
  default_contract_type: contractTypes[0].value,
  default_contract_value: 0,
  show_gross_profit: BoolTypes[0].value,
  can_add_sellers: BoolTypes[0].value,
  company_domain: "",
  seller_type: "PUBLISHER",
  display_id: "",
  monetization_type: "",
  average_us_monthly_page_view: "",
  top_geos: "",
  company_vat_number: "",
  bank_name: "",
  bank_branch_code: "",
  bank_account_number: "",
  bank_iban: "",
  bank_swift: "",
});

const podcasterForm = ref({
  rss_url: "",
  spotify_link: "",
  itunes_link: "",
  avg_per_episode: 0,
  avg_per_month: 0,
});

const podcastersForm = ref([]);

const addPodcastShow = () => {
  podcastersForm.value.push({ ...podcasterForm.value });
};

const removePodcastShow = (itemId) => {
  podcastersForm.value = podcastersForm.value.filter(
    (item, index) => index !== itemId
  );
};

const validatePodcastShows = () => {
  return podcastersForm.value.every(
    (podcast) => podcast.rss_url?.length && podcast.avg_per_month > 0
  );
};

const switchTheme = () => {
  PrimeVue.changeTheme(
    `${theme.value}`,
    `${theme.value === "dark" ? "light" : "dark"}`,
    "theme-link",
    () => {}
  );
  theme.value = theme.value === "dark" ? "light" : "dark";
};

const validateEmail = () => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  emailValid.value =
    form.value.email?.length && re.test(String(form.value.email).toLowerCase());
};

const validateDomain = () => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
  const domain = String(form.value.company_domain).toLowerCase();
  domainValid.value =
    !domain.includes("www.") &&
    re.test(String(form.value.company_domain).toLowerCase());
};

const validatePassword = () => {
  passwordValid.value =
    form.value.password &&
    form.value.password.length >= 8 &&
    /[A-Z]/.test(form.value.password);
};

const submitForm = async () => {
  submitted.value = true;
  validateDomain();
  if (onboardType == "create") {
    validateEmail();
    validatePassword();
  }

  if (
    form.value.name?.length &&
    form.value.monetization_type?.length &&
    form.value.company_name?.length &&
    form.value.company_domain?.length &&
    form.value.company_vat_number?.length &&
    emailValid.value &&
    domainValid.value &&
    passwordValid.value &&
    form.value.average_us_monthly_page_view?.length &&
    form.value.top_geos?.length &&
    validatePodcastShows() &&
    form.value.bank_name?.length &&
    form.value.bank_account_number?.length &&
    form.value.bank_branch_code?.length &&
    form.value.bank_iban?.length &&
    form.value.bank_swift?.length
  ) {
    try {
      store.commit("setLoading", true);
      const response = await axios.post(
        `${API_BASE_URL}/publisher_onboarding`,
        {
          groupData: form.value,
          podcasterData: podcastersForm.value,
          linkToken: token,
        }
      );

      if (response.data?.success) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Publisher submitted successfully!",
          life: 4000,
        });
        router.push("/");
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: response?.data?.message || "Something went wrong...",
          life: 4000,
        });
      }
    } catch (error) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: error.response?.data?.message || "Something went wrong...",
        life: 4000,
      });
      console.error(error);
    } finally {
      store.commit("setLoading", false);
    }
  }
};

onBeforeMount(() => {
  if (!localStorage.getItem("loggedout")) {
    localStorage.clear();
    localStorage.setItem("loggedout", true);
    window.location.reload();
  }
});
</script>
