<template>
  <div
    class="flex flex-column w-full h-full justify-content-between align-items-stretch"
  >
    <div class="flex flex-column align-items-stretch gap-1">
      <div
        class="w-full justify-content-center flex align-items-baseline pt-2 pb-4"
      >
        <JamxTitle logo-color="var(--text-color)" class="w-6 h-2rem" />
      </div>
      <router-link v-if="!adminUserData?.isLimitedAdmin" :to="dashboardLink()"
        ><Button
          label="Dashboard"
          icon-class="mr-3"
          icon="pi pi-home"
          :text="!isLinkActive('Dashboard')"
          class="w-full text-left"
      /></router-link>

      <template v-if="getRoleByState() == 'partner'">
        <router-link to="/groups"
          ><Button
            label="Groups"
            icon-class="mr-3"
            icon="pi pi-th-large"
            :text="!isLinkActive('GroupsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/sellers"
          ><Button
            label="Sellers"
            icon-class="mr-3"
            icon="pi pi-users"
            :text="!isLinkActive('SellersList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/playlists"
          ><Button
            label="Playlists"
            icon-class="mr-3"
            icon="pi pi-bolt"
            :text="!isLinkActive('PlaylistsList')"
            class="w-full text-left"
        /></router-link>
        <router-link v-if="partner?.can_manage_demand_tags" to="/tags"
          ><Button
            label="Tags List"
            icon-class="mr-3"
            icon="pi pi-tag"
            :text="!isLinkActive('PartnerTagsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/developers"
          ><Button
            label="Developer API"
            icon-class="mr-3"
            icon="pi pi-key"
            :text="!isLinkActive('DeveloperAPI')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/publisher-onboarding-links"
          ><Button
            label="Onboard Links"
            icon-class="mr-3"
            icon="pi pi-link"
            :text="!isLinkActive('PublisherOnboardingLinks')"
            class="w-full text-left"
        /></router-link>
      </template>
      <template v-if="getRoleByState() == 'group-admin'">
        <router-link to="/group-admin/sellers"
          ><Button
            label="Domains"
            icon-class="mr-3"
            icon="pi pi-server"
            :text="!isLinkActive('GroupAdminSellersList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/group-admin/developers"
          ><Button
            label="Developer API"
            icon-class="mr-3"
            icon="pi pi-key"
            :text="!isLinkActive('DeveloperAPI')"
            class="w-full text-left"
        /></router-link>
      </template>
      <template v-if="getRoleByState() == 'admin'">
        <router-link to="/admin/custom-reports"
          ><Button
            label="Reports"
            icon-class="mr-3"
            icon="pi pi-home"
            :text="!isLinkActive('Reports')"
            class="w-full text-left"
        /></router-link>
        <router-link v-if="!adminUserData?.isLimitedAdmin" to="/admin/partners"
          ><Button
            label="Partners"
            icon-class="mr-3"
            icon="pi pi-sitemap"
            :text="!isLinkActive('PartnersList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/admin/groups"
          ><Button
            :badge="
              notifications.groupsNotifyCount > 0
                ? notifications.groupsNotifyCount > 5
                  ? '5+'
                  : notifications.groupsNotifyCount
                : null
            "
            badge-severity="danger"
            label="Groups"
            icon-class="mr-3"
            icon="pi pi-th-large"
            :text="!isLinkActive('AdminGroupsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/admin/sellers"
          ><Button
            :badge="
              notifications.sellersNotifyCount > 5
                ? '5+'
                : notifications.sellersNotifyCount
            "
            badge-severity="danger"
            label="Sellers Approvals"
            icon-class="mr-3"
            icon="pi pi-verified"
            :text="!isLinkActive('AdminSellers')"
            class="w-full text-left"
        /></router-link>
        <router-link v-if="!adminUserData?.isLimitedAdmin" to="/admin/tags"
          ><Button
            label="Tags List"
            icon-class="mr-3"
            icon="pi pi-tag"
            :text="!isLinkActive('TagsList')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/tag-groups"
          ><Button
            label="Tag Groups List"
            icon-class="mr-3"
            icon="pi pi-tags"
            :text="!isLinkActive('TagGroupsList')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/config-manager"
          ><Button
            label="Config Manager"
            icon-class="mr-3"
            icon="pi pi-sliders-h"
            :text="!isLinkActive('ConfigManager')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/ads-txt-manager"
          ><Button
            label="Ads.txt Manager"
            icon-class="mr-3"
            icon="pi pi-file-edit"
            :text="!isLinkActive('AdsTxt')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/content-packs"
          ><Button
            label="Content Packs"
            icon-class="mr-3"
            icon="pi pi-gift"
            :text="!isLinkActive('ContentPacks')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/argus-snapshot"
          ><Button
            label="Argus"
            icon-class="mr-3"
            icon="pi pi-eye"
            :text="!isLinkActive('ArgusSnapshot')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/admin/publisher-onboarding-links"
          ><Button
            label="Onboard Links"
            icon-class="mr-3"
            icon="pi pi-link"
            :text="!isLinkActive('AdminPublisherOnboardingLinks')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/admin/scheduled-reports"
          ><Button
            label="Scheduled Reports"
            icon-class="mr-3"
            icon="pi pi-calendar"
            :text="!isLinkActive('AdminReportSchedules')"
            class="w-full text-left"
        /></router-link>
        <router-link
          v-if="!adminUserData?.isLimitedAdmin"
          to="/admin/group-account-managers"
          ><Button
            label="Group Owners"
            icon-class="mr-3"
            icon="pi pi-users"
            :text="!isLinkActive('GroupAccountManagers')"
            class="w-full text-left"
        /></router-link>
      </template>
      <template v-if="getRoleByState() == 'podcaster'">
        <router-link to="/podcaster/shows"
          ><Button
            label="Shows"
            icon-class="mr-3"
            icon="pi pi-wifi"
            :text="!isLinkActive('PodcastShowsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcaster/episodes"
          ><Button
            label="Episodes"
            icon-class="mr-3"
            icon="pi pi-play"
            :text="!isLinkActive('PodcasterEpisodesList')"
            class="w-full text-left"
        /></router-link>
      </template>
      <template v-if="getRoleByState() == 'podcastAdmin'">
        <router-link to="/podcast-admin/custom-reports"
          ><Button
            label="Reports"
            icon-class="mr-3"
            icon="pi pi-table"
            :text="!isLinkActive('PodcastAdminCustomReports')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcast-admin/shows"
          ><Button
            label="Shows"
            icon-class="mr-3"
            icon="pi pi-wifi"
            :text="!isLinkActive('PodcastAdminShowsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcast-admin/episodes"
          ><Button
            label="Episodes"
            icon-class="mr-3"
            icon="pi pi-play"
            :text="!isLinkActive('PodcastAdminEpisodesList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcast-admin/podcasters"
          ><Button
            label="Podcasters"
            icon-class="mr-3"
            icon="pi pi-th-large"
            :text="!isLinkActive('PodcastersList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcast-admin/campaigns"
          ><Button
            label="Campaigns"
            icon-class="mr-3"
            icon="pi pi-volume-up"
            :text="!isLinkActive('PodcastAdminCampaignsList')"
            class="w-full text-left"
        /></router-link>
        <router-link to="/podcast-admin/alert-room"
          ><Button
            label="Activity Log"
            icon-class="mr-3"
            icon="pi pi-history"
            :text="!isLinkActive('PodcastAdminAlertRoom')"
            class="w-full text-left"
        /></router-link>

        <router-link to="/podcast-admin/podcast-generation">
          <Button
            label="Auxigen"
            icon-class="mr-3"
            icon="pi pi-microphone"
            :text="!isLinkActive('AddPodcastGeneration')"
            class="w-full text-left"
        /></router-link>
      </template>
    </div>
    <div class="w-full">
      <Button
        label="Logout"
        @click="logout()"
        icon-class="mr-3"
        icon="pi pi-sign-out"
        text
        class="text-left w-full"
      />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { onBeforeMount, ref } from "vue";
import Button from "primevue/button";
import LogoImg from "@/assets/pulsar.svg";
import JamxTitle from "@/components/icons/JamxTitle.vue";
import axios from "axios";
import { API_BASE_URL } from "@/consts";

const router = useRouter();
const store = useStore();
const partner = JSON.parse(localStorage.getItem("partner"));

// const adminUserData = JSON.parse(localStorage.getItem("admin"));
const adminUserData = store.state.admin;

const notifications = ref({
  sellersNotifyCount: 0,
  groupsNotifyCount: 0,
});

const getRoleByState = () => {
  if (store.getters.isSellerLoggedIn) {
    return "seller";
  } else if (store.getters.isGroupAdminLoggedIn) {
    return "group-admin";
  } else if (store.getters.isAdminLoggedIn) {
    return "admin";
  } else if (store.getters.isPodcasterLoggedIn) {
    return "podcaster";
  } else if (store.getters.isPodcastAdminLoggedIn) {
    return "podcastAdmin";
  } else if (store.getters.isLoggedIn) {
    return "partner";
  }
};

const dashboardLink = () => {
  switch (getRoleByState()) {
    case "seller":
      return "/seller/dashboard";
    case "group-admin":
      return "/group-admin/dashboard";
    case "partner":
      return "/dashboard";
    case "admin":
      return "/admin/dashboard-new";
    case "podcaster":
      return "/podcaster/dashboard";
    case "podcastAdmin":
      return "/podcast-admin/dashboard";
    default:
      return "/dashboard";
  }
};

const isLinkActive = (rtrName) =>
  router.currentRoute.value.name
    ? router.currentRoute.value.name.includes(rtrName)
    : false;

const logout = () => {
  localStorage.clear();
  window.location.replace("/");
};

const getNotificationCounts = async () => {
  try {
    if (!localStorage.getItem("adminToken")?.length) return;
    const response = await axios.get(
      `${API_BASE_URL}/admin/notification-counts`
    );
    if (response.data.success) {
      notifications.value = response.data.data;
    }
  } catch (error) {
    console.error("Error fetching notification counts:", error);
  }
};

onBeforeMount(() => {
  getNotificationCounts();
});
</script>
