<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Ads.txt Management</h2>
    <Card class="mb-3">
      <template #title>
        <div class="text-xl">Manage Ads.txt Rows</div>
      </template>
      <template #content>
        <!-- Search input field -->
        <div class="flex mb-3">
          <span class="p-input-icon-left w-full md:w-30rem">
            <i class="pi pi-search" />
            <InputText
              v-model="searchQuery"
              placeholder="Search rows..."
              class="w-full"
            />
          </span>
        </div>

        <DataTable
          :value="filteredAdsTxtRows"
          :sortMode="'multiple'"
          :multiSortMeta="multiSortMeta"
          @sort="onSort"
          class="mb-3 p-datatable-sm"
        >
          <Column
            field="row_string"
            header="Row String"
            :sortable="true"
          ></Column>
          <Column
            field="partner_name"
            header="Partner Name"
            :sortable="true"
          ></Column>
          <Column field="type" header="Type" :sortable="true"></Column>
          <Column field="priority" header="Priority" :sortable="true">
            <template #body="slotProps">
              <Tag
                :severity="priorityList[slotProps.data.priority - 1]?.severity"
                :value="priorityList[slotProps.data.priority - 1]?.label"
              ></Tag>
            </template>
          </Column>
          <Column header="Actions">
            <template #body="slotProps">
              <div class="flex gap-2">
                <Button
                  icon="pi pi-pencil"
                  size="small"
                  severity="help"
                  @click="openAdsTxtUpdateModal(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-danger p-button-sm"
                  @click="deleteRow(slotProps.data.id)"
                />
              </div>
            </template>
          </Column>
        </DataTable>
        <div class="grid">
          <div class="flex flex-column gap-1 col-12 md:col-6">
            <label for="row_string">Row String</label>
            <InputText
              id="row_string"
              v-model="newRow.row_string"
              placeholder="Row String"
              class="w-full"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6">
            <label for="partner_name">Partner Name</label>
            <AutoComplete
              input-id="partner_name"
              v-model="newRow.partner_name"
              :suggestions="filteredPartners"
              @complete="searchPartner"
              placeholder="Partner Name"
              class="w-full"
              input-class="w-full"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6">
            <label for="row_type">Type</label>
            <Dropdown
              input-id="row_type"
              v-model="newRow.type"
              :options="typeOptions"
              optionLabel="name"
              optionValue="value"
              placeholder="Select Type"
              class="w-full"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6">
            <label for="priority">Priority</label>
            <Dropdown
              input-id="priority"
              v-model="newRow.priority"
              :options="priorityOptions"
              optionLabel="name"
              optionValue="value"
              placeholder="Select Priority"
              class="w-full"
            />
          </div>
          <div class="flex flex-column gap-1 col-12">
            <Button label="Add Row" @click="addRow" class="w-full" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="w-full flex justify-content-end">
          <Button
            class="flex-1 sm:flex-initial"
            label="Notify Groups"
            @click="showGroupsModal = true"
          />
        </div>
      </template>
    </Card>
    <Dialog
      v-model:visible="showGroupsModal"
      header="Select Groups to Notify"
      class="w-full max-w-30rem"
      :closable="false"
      :modal="true"
    >
      <div class="flex flex-column gap-1 mb-3">
        <label for="groups_list">Select Groups:</label>
        <MultiSelect
          input-id="groups_list"
          v-model="selectedGroupsForNotify"
          :options="groupListOptions"
          option-label="name"
          placeholder="Select Groups"
          option-value="id"
          filter
          filter-placeholder="Search Groups"
        />
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          @click="notifyGroups"
          label="Send Notification"
        />
      </div>
    </Dialog>
    <Dialog
      v-model:visible="showUpdateAdsTxtModal"
      header="Update type and priority"
      class="w-full max-w-30rem"
      :closable="true"
      :modal="true"
    >
      <div class="flex flex-column gap-1 mb-3">
        <label for="adstxt_type">Select Type:</label>
        <Dropdown
          input-id="adstxt_type"
          v-model="selectedAdsTxtRow.type"
          :options="typeOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Type"
          class="w-full"
        />
      </div>
      <div class="flex flex-column gap-1 mb-3">
        <label for="adstxt_priority">Select Priority:</label>
        <Dropdown
          input-id="adstxt_priority"
          v-model="selectedAdsTxtRow.priority"
          :options="priorityOptions"
          optionLabel="name"
          optionValue="value"
          placeholder="Select Type"
          class="w-full"
        />
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          @click="updateAdsTxtTypeAndPriority"
          label="Save"
        />
        <Button
          class="flex-1 sm:flex-initial"
          severity="secondary"
          outlined
          @click="showUpdateAdsTxtModal = false"
          label="Cancel"
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import axios from "axios";
import Card from "primevue/card";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import MultiSelect from "primevue/multiselect";
import AutoComplete from "primevue/autocomplete";
import { API_BASE_URL } from "@/consts";
import Dropdown from "primevue/dropdown";
import Tag from "primevue/tag";

const store = useStore();
const toast = useToast();
const token = localStorage.getItem("adminToken");

const adsTxtRows = ref([]);
const searchQuery = ref(""); // New search query ref
const newRow = ref({ row_string: "", partner_name: "", type: "", priority: 3 });
const groupListOptions = ref([]);
const selectedGroupsForNotify = ref([]);
const showGroupsModal = ref(false);
const filteredPartners = ref([]);

const showUpdateAdsTxtModal = ref(false);
const selectedAdsTxtRow = ref({
  id: -1,
  type: "",
  priority: -1,
});

const multiSortMeta = ref([]);

// Filter rows based on search query
const filteredAdsTxtRows = computed(() => {
  if (!searchQuery.value) {
    return adsTxtRows.value;
  }

  const query = searchQuery.value.toLowerCase();
  return adsTxtRows.value.filter(
    (row) =>
      row.row_string.toLowerCase().includes(query) ||
      row.partner_name.toLowerCase().includes(query) ||
      row.type.toLowerCase().includes(query) ||
      priorityList[row.priority - 1]?.label.toLowerCase().includes(query)
  );
});

const typeOptions = [
  { name: "Audio", value: "audio" },
  { name: "Display", value: "display" },
  { name: "Video", value: "video" },
  { name: "App Audio", value: "app_audio" },
  { name: "App Display", value: "app_display" },
  { name: "App Video", value: "app_video" },
];

const priorityOptions = [
  { name: "Critical", value: 1 },
  { name: "High", value: 2 },
  { name: "Low", value: 3 },
];

const priorityList = [
  { label: "Critical", severity: "danger" },
  { label: "High", severity: "warning" },
  { label: "Low", severity: "secondary" },
];

const onSort = (event) => {
  multiSortMeta.value = event.multiSortMeta;
};

const uniquePartners = computed(() => {
  return [...new Set(adsTxtRows.value.map((row) => row.partner_name))];
});

const searchPartner = (event) => {
  const query = event.query.toLowerCase();
  filteredPartners.value = uniquePartners.value.filter((partner) =>
    partner.toLowerCase().includes(query)
  );
};

const fetchAdsTxtRows = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(`${API_BASE_URL}/adstxt-rows`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    adsTxtRows.value = response.data.result;
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch Ads.txt rows.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const isDuplicateRowString = (newRowString) => {
  const normalizedNewRowString = normalizeRowString(newRowString);
  return adsTxtRows.value.some(
    (row) => normalizeRowString(row.row_string) === normalizedNewRowString
  );
};

const normalizeRowString = (rowString) => {
  return rowString.trim().toLowerCase().replace(/\s+/g, "");
};

const addRow = async () => {
  if (isDuplicateRowString(newRow.value.row_string)) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Duplicate row string. This entry already exists.",
      life: 4000,
    });
    return;
  }

  try {
    store.commit("setLoading", true);
    await axios.post(`${API_BASE_URL}/adstxt-rows`, newRow.value, {
      headers: { Authorization: `Bearer ${token}` },
    });
    await fetchAdsTxtRows();
    newRow.value = { row_string: "", partner_name: "", type: "", priority: 3 };
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Row added successfully.",
      life: 4000,
    });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to add row.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const deleteRow = async (id) => {
  try {
    store.commit("setLoading", true);
    await axios.delete(`${API_BASE_URL}/adstxt-rows/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    await fetchAdsTxtRows();
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Row deleted successfully.",
      life: 4000,
    });
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete row.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const fetchGroupList = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/member-list/group`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.data.success) {
      groupListOptions.value = response.data.groups;
    }
  } catch (error) {
    console.error(error);
  }
};

const notifyGroups = async () => {
  try {
    showGroupsModal.value = false;
    store.commit("setLoading", true);
    const response = await axios.post(
      `${API_BASE_URL}/adstxt/notify`,
      {
        groups_to_notify: selectedGroupsForNotify.value,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.data.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Successfully sent notifications.",
        life: 4000,
      });
    } else {
      throw new Error("Failed to send notifications");
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, couldn't send notifications.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
    selectedGroupsForNotify.value = [];
  }
};

const openAdsTxtUpdateModal = (row) => {
  selectedAdsTxtRow.value = { ...row };
  showUpdateAdsTxtModal.value = true;
};

const updateAdsTxtTypeAndPriority = async () => {
  store.commit("setLoading", true);
  try {
    showUpdateAdsTxtModal.value = false;
    const response = await axios.post(
      `${API_BASE_URL}/adstxt/type_and_priority`,
      { value: selectedAdsTxtRow.value },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      await fetchAdsTxtRows();
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Successfully updated.",
        life: 4000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something went wrong, couldn't update.",
        life: 4000,
      });
    }
  } catch (error) {
    console.error(error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Something went wrong, server error.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

onBeforeMount(() => {
  fetchAdsTxtRows();
  fetchGroupList();
});
</script>

<style scoped>
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.25rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr.compact-row {
  height: 2.5rem;
}

.p-button.p-button-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
</style>
