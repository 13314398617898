export const API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001/api"
    : "https://link.jamx.ai:3100/api";

export const adsTxtFixed = (group_id, display_id) => `
jamx.ai, ${group_id || 0}, DIRECT
audiopulsar.com, ${group_id || 0}, DIRECT
podium-audio.com, ${group_id || 0}, DIRECT`;

export const limitedAdminPages = [
  // "AdminDashboard",
  // "AdminNewDashboard",
  "AdminGroupsList",
  "CustomReports",
  "AdminSellers",
  "AdminPublisherOnboardingLinks",
  "AdminReportSchedules",
  "AdminUpdateSellerGroup",
  "AdminAddSeller",
  "AdminUpdateSeller",
  "AdminAddScheduledReport",
];
