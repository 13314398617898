<template>
  <LayoutApp
    v-if="
      !isPublicRoute &&
      (store.getters.isLoggedIn ||
        store.getters.isSellerLoggedIn ||
        store.getters.isGroupAdminLoggedIn ||
        store.getters.isAdminLoggedIn ||
        store.getters.isPodcasterLoggedIn ||
        store.getters.isPodcastAdminLoggedIn)
    "
  />
  <LayoutPublic v-else-if="isPublicRoute" />
  <LayoutLogin v-else />
  <Toast />
  <ConfirmDialog :style="{ maxWidth: '40rem' }" />
  <Loader v-if="store.getters.isLoading" />
</template>

<script setup>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import LayoutLogin from "@/components/layouts/LayoutLogin.vue";
import LayoutApp from "@/components/layouts/LayoutApp.vue";
import LayoutPublic from "@/components/layouts/LayoutPublic.vue";
import { useStore } from "vuex";
import { computed } from "vue";
import { useRoute } from "vue-router";
import Loader from "./components/Loader.vue";

const store = useStore();
const route = useRoute();

const isPublicRoute = computed(() => {
  return route.matched.some((record) => record.meta.public);
});
</script>
<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
