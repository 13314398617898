<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      Group Account Managers
    </h2>
    <Card>
      <template #header>
        <div
          class="p-3 pb-0 flex flex-column sm:flex-row justify-content-between gap-3"
        >
          <Button
            class="justify-content-center"
            @click="openAddAccountManagerDialog"
          >
            Add New Account Manager
          </Button>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="accountManagers"
          :paginator="true"
          :rows="10"
          class="p-datatable-sm"
          :striped-rows="true"
        >
          <Column field="id" header="ID" :sortable="true"></Column>
          <Column field="name" header="Name" :sortable="true"></Column>
          <Column field="email" header="Email" :sortable="true"></Column>
          <Column header="Edit">
            <template #body="slotProps">
              <div class="flex gap-2 justify-content-center">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-success"
                  @click="openEditAccountManagerDialog(slotProps.data)"
                ></Button>
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  @click="
                    (e) => confirmDeleteAccountManager(e, slotProps.data.id)
                  "
                ></Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
  </div>
  <GroupAccountManagerDialog
    :visible="showAccountManagerDialogData"
    :account-manager="accountManagerDialogData"
    :is-edit="isEditDialog"
    @save-account-manager="saveAccountManager"
    @close-modal="showAccountManagerDialogData = false"
  />
</template>

<script setup>
import { API_BASE_URL } from "@/consts";
import axios from "axios";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import { useToast } from "primevue/usetoast";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";
import GroupAccountManagerDialog from "./GroupAccountManagerDialog.vue";
import { useConfirm } from "primevue/useconfirm";
import Button from "primevue/button";

const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const accountManagers = ref([]);
const accountManagerDialogData = ref({
  name: "",
  email: "",
});
const isEditDialog = ref(false);
const showAccountManagerDialogData = ref(false);

const saveAccountManager = (accountManager) => {
  if (isEditDialog.value) updateAccountManager(accountManager);
  else addAccountManager(accountManager);
  showAccountManagerDialogData.value = false;
};

const openAddAccountManagerDialog = () => {
  isEditDialog.value = false;
  accountManagerDialogData.value = { name: "", email: "" };
  showAccountManagerDialogData.value = true;
};

const openEditAccountManagerDialog = (accountManager) => {
  isEditDialog.value = true;
  accountManagerDialogData.value = accountManager;
  showAccountManagerDialogData.value = true;
};

const confirmDeleteAccountManager = (e, managerId) => {
  confirm.require({
    target: e.currentTarget,
    message: "Do you want to delete this manager?",
    icon: "pi pi-info-circle",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Delete",
      severity: "danger",
    },
    accept: async () => await deleteAccountManager(managerId),
  });
};

const fetchAccountManagers = async () => {
  store.commit("setLoading", true);
  try {
    const response = await axios.get(`${API_BASE_URL}/account-managers`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });

    if (response.data.success) {
      accountManagers.value = response.data.data;
    }
  } catch (error) {
    console.error("Error fetching account managers:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error fetching group account managers",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const addAccountManager = async (accountManager) => {
  store.commit("setLoading", true);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/account-managers`,
      accountManager,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );

    if (response.data.success) {
      await fetchAccountManagers();
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Account manager added successfully",
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error adding account manager:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error adding account manager",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const updateAccountManager = async (accountManager) => {
  store.commit("setLoading", true);
  try {
    const response = await axios.put(
      `${API_BASE_URL}/account-managers`,
      accountManager,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );

    if (response.data.success) {
      const index = accountManagers.value.findIndex(
        (am) => am.id === accountManager.id
      );
      accountManagers.value[index] = response.data.data.accountManager;
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Account manager updated successfully",
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error updating account manager:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error updating account manager",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const deleteAccountManager = async (accountManagerId) => {
  store.commit("setLoading", true);
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/account-managers/${accountManagerId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );

    if (response.data.success) {
      accountManagers.value = accountManagers.value.filter(
        (am) => am.id !== accountManagerId
      );
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Account manager deleted successfully",
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error deleting account manager:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Error deleting account manager",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

onBeforeMount(() => {
  fetchAccountManagers();
});
</script>
