<template>
  <Card class="mb-4">
    <template #title>
      <div class="text-xl">{{ title }}</div>
    </template>
    <template #content>
      <div v-if="showChart" class="relative">
        <line-chart
          class="pb-4"
          :data="chartData"
          :options="{ responsive: true, maintainAspectRatio: false }"
          :legend="chartLegend"
        ></line-chart>
        <LoaderSm v-if="loading" />
      </div>
      <DataTable
        :value="tableData"
        :columns="columns"
        :paginator="true"
        :rows="rows"
        :totalRecords="totalRecords"
        lazy
        @page="onPage"
        @sort="onSort"
        :loading="loading"
        class="p-datatable-striped p-datatable-sm"
        :defaultSortOrder="-1"
      >
        <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          sortable
        ></Column>
        <template #loadingicon>
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </template>
        <template #paginatorstart>
          <Button type="button" icon="pi pi-refresh" @click="onRefresh" text />
        </template>
        <template #paginatorend>
          <Button
            type="button"
            icon="pi pi-download"
            text
            @click="onDownloadCsvClick"
          />
        </template>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { ref, computed, watch, emit } from "vue";
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import LoaderSm from "./LoaderSm.vue";
import { formatCurrencyNumber } from "../helpers";

export default {
  components: {
    Card,
    DataTable,
    Column,
    Button,
    LoaderSm,
  },
  props: {
    title: String,
    dataMode: String,
    columns: Array,
    showChart: Boolean,
    chartLegend: String,
    data: Array,
    loading: Boolean,
    rows: Number,
    totalRecords: Number,
    csvData: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const onDownloadCsvClick = () => {
      emit("download-csv", props.dataMode);
    };

    const chartData = computed(() => {
      if (!props.data || props.data.length === 0) return {};

      if (
        props.dataMode === "partner_daily" ||
        props.dataMode === "partnerDaily"
      ) {
        return props.data.map((item) => [
          item.date_trunc,
          parseFloat(item.revenue),
        ]);
      } else {
        const chartData = {};
        props.data.forEach((item) => {
          const key =
            props.dataMode === "partner_per_domain_daily" ||
            props.dataMode === "partnerPerDomainDaily"
              ? item.url
              : props.dataMode === "partner_per_geo_daily" ||
                props.dataMode === "partnerPerGeoDaily"
              ? item.geo
              : props.dataMode === "partner_per_seller_daily" ||
                props.dataMode === "partnerPerSellerDaily"
              ? item.sub_seller_id
              : item.date_trunc;

          if (!chartData[key]) {
            chartData[key] = {};
          }
          chartData[key][item.date_trunc] = parseFloat(item.revenue);
        });

        return Object.entries(chartData).map(([name, data]) => ({
          name,
          data: Object.entries(data).reduce((acc, [date, revenue]) => {
            acc[date] = revenue;
            return acc;
          }, {}),
        }));
      }
    });

    const tableData = computed(() => {
      if (!props.data || props.data.length === 0) {
        //  console.warn(`No data available for ${props.dataMode}`);
        return [];
      }
      return props.data.map((item) => ({
        ...item,
        loads: item.loads?.toLocaleString() ?? "",
        impressions: item.impressions?.toLocaleString() ?? "",
        revenue: formatCurrencyNumber(item.revenue),
        gross_profit: formatCurrencyNumber(item.gross_profit),
        cost: formatCurrencyNumber(item.cost),
        profit: formatCurrencyNumber(item.profit),
        ecpm: formatCurrencyNumber(item.ecpm),
        cpm: formatCurrencyNumber(item.cpm),
      }));
    });

    const csvData = computed(() => props.data);

    const onPage = (e) => {
      emit("fetch-data", {
        dataMode: props.dataMode,
        page: e.page,
        rows: e.rows,
      });
    };

    const onSort = (e) => {
      emit("fetch-data", {
        dataMode: props.dataMode,
        sortField: e.sortField,
        sortOrder: e.sortOrder,
      });
    };

    const onRefresh = () => {
      emit("fetch-data", {
        dataMode: props.dataMode,
        page: 0,
        rows: props.rows,
      });
    };

    return {
      chartData,
      tableData,
      csvData,
      onPage,
      onSort,
      onRefresh,
      onDownloadCsvClick,
    };
  },
};
</script>
