<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ sellerId ? "Update" : "Add" }} Seller
    </h2>
    <form @submit.prevent="confirmCPMHighBeforeSubmit()">
      <Card class="mb-3">
        <template #title>
          <div class="text-xl">Seller Information</div>
        </template>
        <template #content>
          <div class="grid">
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="name">Name </label>
              <InputText
                id="name"
                v-model="form.name"
                :class="{ 'p-invalid': submitted && !form.name }"
              />
              <small v-if="submitted && !form.name" class="p-error"
                >Name is required</small
              >
            </div>
            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="email">Email</label>
              <InputText
                id="email"
                type="email"
                v-model="form.email"
                :class="{
                  'p-invalid':
                    submitted &&
                    (!form.email || !emailValid) &&
                    !form.sellerGroup,
                }"
              />
              <small
                v-if="submitted && !emailValid && !form.sellerGroup"
                class="p-error"
                >Invalid email format</small
              >
            </div>

            <div
              v-if="!store.getters.isGroupAdminLoggedIn"
              class="flex flex-column gap-1 col-12 md:col-6"
            >
              <label for="sellerGroup">Seller Group</label>
              <Dropdown
                id="sellerGroup"
                v-model="form.sellerGroup"
                :options="sellerGroups"
                :class="{ 'p-invalid': submitted && !form.sellerGroup }"
                placeholder="Select or Enter Seller Group"
                optionLabel="label"
                optionValue="value"
                filter
                showClear
              />
              <small v-if="submitted && !form.sellerGroup" class="p-error"
                >Group is required</small
              >
            </div>

            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="domain">Domain </label>
              <InputText
                id="domain"
                v-model="form.domain"
                :class="{
                  'p-invalid': submitted && (!form.domain || !domainValid),
                }"
              />
              <small v-if="submitted && !domainValid" class="p-error"
                >Invalid domain format</small
              >
            </div>

            <div class="flex flex-column gap-1 col-12 md:col-6">
              <label for="password">Password</label>
              <InputText
                id="password"
                autocomplete="new-password"
                type="password"
                v-model="form.password"
                placeholder="Enter password"
                :class="{
                  'p-invalid':
                    submitted &&
                    (!form.password || !passwordValid) &&
                    !form.sellerGroup,
                }"
              />
              <small
                v-if="submitted && !passwordValid && !form.sellerGroup"
                class="p-error"
                >Password must be atleast 8 chars, with upper-case and
                lower-case letters</small
              >
            </div>
            <div
              class="flex flex-column gap-1 col-12 md:col-6"
              v-if="store.getters.isAdminLoggedIn"
            >
              <label for="tier">Tier:</label>
              <Dropdown
                id="tier"
                v-model="form.tier"
                :options="tierOptions"
                placeholder="Select Tier"
              />
            </div>
            <div
              class="flex flex-column gap-1 col-12 md:col-6"
              v-if="store.getters.isAdminLoggedIn"
            >
              <label for="site_categories">Site Categories</label>
              <MultiSelect
                input-id="site_categories"
                v-model="form.site_categories"
                :options="categories"
                display="chip"
                placeholder="Select Categories"
                filter
                filter-placeholder="Search Categories"
              />
            </div>
          </div>
        </template>
      </Card>
      <div class="mb-3">
        <div class="grid align-items-stretch">
          <div
            v-if="!store.getters.isGroupAdminLoggedIn"
            class="col-12 md:col-6"
          >
            <Card class="h-full">
              <template #title>
                <div class="text-xl">Contract Details</div>
              </template>
              <template #content>
                <div class="grid">
                  <div class="flex flex-column gap-1 col-12">
                    <label for="contractType">Contract Type:</label>
                    <Dropdown
                      id="contractType"
                      v-model="form.contractType"
                      :options="contractTypes"
                      placeholder="Select Type"
                      optionLabel="label"
                      optionValue="value"
                      @change="onContractTypeChange"
                    />
                  </div>
                  <template v-if="form.contractType === 'cpm'">
                    <div class="flex flex-column gap-1 col-12">
                      <label for="cpm_per_geo">CPM per Geo</label>
                      <div
                        v-for="(item, index) in form.cpm_per_geo"
                        :key="index"
                        class="flex gap-1"
                      >
                        <Dropdown
                          v-model="item.geo"
                          :options="countryList"
                          optionLabel="name.common"
                          optionValue="cca2"
                          placeholder="Select Country"
                          filter
                          class="w-full"
                        />
                        <InputText
                          v-model.number="item.cpm"
                          placeholder="Enter CPM"
                          class="w-full"
                        />
                        <Button
                          icon="pi pi-times"
                          class="p-button-danger"
                          text
                          @click="removeGeo(index)"
                        />
                      </div>
                      <div class="flex gap-1">
                        <Dropdown
                          placeholder="Rest Of The World"
                          disabled
                          class="w-full"
                        />
                        <InputText
                          v-model.number="form.contractValue"
                          placeholder="Enter CPM"
                          class="w-full"
                        />
                        <Button
                          icon="pi pi-times"
                          class="p-button-danger"
                          style="visibility: hidden"
                          text
                        />
                      </div>
                    </div>
                    <div class="flex flex-column gap-1 col-12">
                      <Button
                        label="Add Country"
                        icon="pi pi-plus"
                        @click="
                          form.cpm_per_geo.push({
                            geo: '',
                            cpm: 0,
                          })
                        "
                      ></Button>
                    </div>
                  </template>
                  <template v-else-if="form.contractType === 'revshare'">
                    <div class="flex flex-column gap-1 col-12">
                      <label for="contractValue"
                        >{{ contactValueLabel }}:</label
                      >
                      <input
                        id="contractValue"
                        type="range"
                        min="0"
                        max="95"
                        step="5"
                        v-model="form.contractValue"
                      />
                      <span style="text-align: left; width: 20%"
                        >{{ form.contractValue }}%</span
                      >
                    </div>
                  </template>
                  <small
                    v-if="submitted && !validateContractDetails()"
                    class="p-error"
                    >Valid contract params are required</small
                  >
                </div>
              </template>
            </Card>
          </div>
          <div class="col-12 md:col-6">
            <Card class="h-full">
              <template #title>
                <div class="text-xl">Content</div>
              </template>
              <template #content>
                <div class="grid">
                  <div class="flex flex-column gap-1 col-12">
                    <label for="contentType">Content Source</label>
                    <Dropdown
                      id="contentType"
                      v-model="form.contentType"
                      :options="contentTypes"
                      optionLabel="label"
                      optionValue="value"
                      placeholder="Select Content Type"
                    />
                  </div>
                  <div
                    class="flex flex-column gap-1 col-12"
                    v-if="form.contentType === 'categories'"
                  >
                    <label for="categories">Categories</label>
                    <MultiSelect
                      input-id="categories"
                      v-model="form.categories"
                      :options="categories"
                      display="chip"
                      placeholder="Select Categories"
                      filter
                      filter-placeholder="Search Categories"
                    />
                  </div>

                  <div
                    class="flex flex-column gap-1 col-12"
                    v-if="form.contentType === 'rss_feed'"
                  >
                    <label for="rssFeedUrl">RSS Feed URL</label>
                    <InputText
                      id="rssFeedUrl"
                      v-model="form.rssFeedUrl"
                      :class="{
                        'p-invalid':
                          submitted && (!form.rssFeedUrl || !rssFeedUrlValid),
                      }"
                    />
                    <small v-if="submitted && !rssFeedUrlValid" class="p-error">
                      Invalid RSS Feed URL format
                    </small>
                  </div>

                  <div
                    class="flex flex-column gap-1 col-12"
                    v-if="form.contentType === 'playlist'"
                  >
                    <label for="playlists">Playlists</label>
                    <Dropdown
                      v-if="playlistsOptions.length"
                      id="playlists"
                      v-model="form.playlists"
                      :options="playlistsOptions"
                      optionLabel="name"
                      optionValue="uuid"
                      placeholder="Select Playlists"
                      multiple
                    />
                  </div>

                  <div
                    class="flex flex-column gap-1 col-12"
                    v-if="form.contentType === 'shows'"
                  >
                    <label for="showSelection">Select Shows</label>
                    <MultiSelect
                      id="showSelection"
                      v-model="form.selectedShows"
                      :options="podcastShowsOptions"
                      optionLabel="label"
                      optionValue="value"
                      option-group-label="label"
                      option-group-children="items"
                      placeholder="Select Shows"
                      display="chip"
                      filter
                      :filter-fields="['label']"
                      filter-placeholder="Search Shows"
                    >
                      <template #optiongroup="slotProps">
                        <div class="flex align-items-center">
                          <Checkbox
                            :modelValue="isAllShowsSelected(slotProps.option)"
                            @change="toggleAllShows(slotProps.option)"
                            :binary="true"
                          />
                          <span class="ml-2">{{ slotProps.option.label }}</span>
                        </div>
                      </template>
                    </MultiSelect>
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <div class="col-12 md:col-6">
            <Card class="h-full">
              <template #title>
                <div class="text-xl">Custom Slogan</div>
              </template>
              <template #content>
                <div class="grid">
                  <div class="flex flex-column gap-1 col-12">
                    <label for="custom_slogan_text">Slogan Text </label>
                    <InputText
                      id="custom_slogan_text"
                      v-model="form.custom_slogan_text"
                    />
                  </div>
                  <div class="flex flex-column gap-1 col-12">
                    <label for="custom_slogan_link">Slogan Link</label>
                    <InputText
                      id="custom_slogan_link"
                      v-model="form.custom_slogan_link"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <div class="col-12 md:col-6">
            <Card class="h-full">
              <template #title>
                <div class="text-xl">Geolocation Restriction</div>
              </template>
              <template #content>
                <div class="grid">
                  <div class="flex flex-column gap-1 col-12">
                    <label for="geo_bl">Geolocation Blacklist</label>
                    <MultiSelect
                      inputId="geo_bl"
                      v-model="form.geo_bl"
                      :options="countryList"
                      optionLabel="name.common"
                      optionValue="cca2"
                      placeholder="Select Countries"
                      display="chip"
                      filter
                      class="w-full"
                    />
                  </div>
                  <div class="flex flex-column gap-1 col-12">
                    <label for="geo_wl">Geolocation Whitelist</label>
                    <MultiSelect
                      inputId="geo_wl"
                      v-model="form.geo_wl"
                      :options="countryList"
                      optionLabel="name.common"
                      optionValue="cca2"
                      placeholder="Select Countries"
                      display="chip"
                      filter
                      class="w-full"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div class="w-full flex justify-content-end gap-2">
        <Button
          class="flex-1 sm:flex-initial"
          outlined
          label="Cancel"
          @click="router.go(-1)"
        />
        <Button class="flex-1 sm:flex-initial" type="submit" label="Submit" />
      </div>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Checkbox from "primevue/checkbox";

import Button from "primevue/button";
import Card from "primevue/card";
import MultiSelect from "primevue/multiselect";
import axios from "axios";
import { API_BASE_URL } from "../consts";

export default {
  components: {
    InputText,
    Dropdown,
    Button,
    Card,
    MultiSelect,
    Checkbox,
  },

  setup() {
    const router = useRouter();
    const store = useStore();
    const toast = useToast();
    const confirm = useConfirm();

    const submitted = ref(false);
    const searchText = ref("");

    const emailValid = ref(true);
    const domainValid = ref(true);
    const rssFeedUrlValid = ref(true);
    const contractValueValid = ref(true);
    const passwordValid = ref(true);
    const partnerId = router.currentRoute.value.query.partnerId;
    const contentShowsOnly = ref(false);

    let localStorageKey = "token";

    if (store.getters.isGroupAdminLoggedIn) localStorageKey = "groupAdminToken";

    if (store.getters.isAdminLoggedIn) localStorageKey = "adminToken";

    const token = localStorage.getItem(localStorageKey);

    const contentTypes = computed(() =>
      [
        { label: "Categories", value: "categories" },
        { label: "RSS Feed", value: "rss_feed" },
        { label: "Playlists", value: "playlist" },
        { label: "Shows", value: "shows" },
      ].filter((item) =>
        contentShowsOnly.value ? item.value == "shows" : true
      )
    );

    const contractTypes = [
      { label: "Per Load", value: "cpm" },
      { label: "Revenue Share", value: "revshare" },
    ];

    const tierOptions = ["3", "2", "1"];

    const form = ref({
      name: "",
      email: "",
      domain: "",
      categories: [],
      contractType: contractTypes[0].value,
      contractValue: 0,
      sellerId: null,
      contentType: contentTypes.value[0].value,
      selectedShows: [],
      rssFeedUrl: "",
      playlists: [],
      password: "",
      sellerGroup: store.getters.isGroupAdminLoggedIn
        ? JSON.parse(localStorage.groupAdmin).id
        : null,
      custom_slogan_text: "",
      custom_slogan_link: "",
      consent_approved: false,
      geo_bl: [],
      geo_wl: [],
      tier: "3",
      site_categories: [],
      cpm_per_geo: [],
    });

    const playlistsOptions = ref([]);
    const sellerGroups = ref([]);
    const categories = ref([]);
    const initialRSSUrl = ref("");
    const countryList = ref([]);
    const podcastShowsOptions = ref([]);
    const allShowsList = ref([]);
    const initialContractValue = ref(0);

    const sellerId = router.currentRoute.value.params.sellerId;

    if (sellerId) {
      form.value.sellerId = sellerId;
    }

    const contactValueLabel = computed(() => {
      if (form.value.contractType === "cpm") {
        return "CPM Rate";
      } else {
        return "Share Percent";
      }
    });

    const removeGeo = (index) => {
      form.value.cpm_per_geo.splice(index, 1);
    };

    const filterShowsByPublisherId = (publisherId) => {
      if (!publisherId) return false;

      const connectedPodcasterId = sellerGroups.value.find(
        (item) => item.value == publisherId
      )?.connected_podcaster_id;

      if (!connectedPodcasterId) return false;

      return allShowsList.value.filter(
        (item) => item.podcaster_id == connectedPodcasterId
      );
    };

    const checkIfGroupConnectedPodcaster = (groupId) => {
      if (groupId) {
        const filteredShowOptions = filterShowsByPublisherId(groupId);
        if (filteredShowOptions) {
          getGroupedShows(filteredShowOptions);
          contentShowsOnly.value = true;
          form.value.selectedShows = form.value.selectedShows.filter((item) =>
            podcastShowsOptions.value.some((element) =>
              element.items.some((child) => child.value == item)
            )
          );
          form.value.contentType = contentTypes.value[0].value;
        } else {
          contentShowsOnly.value = false;
          getGroupedShows(allShowsList.value);
        }
      }
    };

    const isAllShowsSelected = (podcaster) => {
      return podcaster.items.every((show) =>
        form.value.selectedShows.includes(show.value)
      );
    };

    const toggleAllShows = (podcaster) => {
      const allSelected = isAllShowsSelected(podcaster);
      podcaster.items.forEach((show) => {
        const index = form.value.selectedShows.indexOf(show.value);
        if (allSelected && index > -1) {
          form.value.selectedShows.splice(index, 1);
        } else if (!allSelected && index === -1) {
          form.value.selectedShows.push(show.value);
        }
      });
    };

    const getGroupedShows = (showsList) => {
      const groupedShows = showsList.reduce((acc, show, index) => {
        if (!acc[show.podcaster_email]) {
          acc[show.podcaster_email] = {
            label: `${show.name} (${show.podcaster_email})`,
            items: [],
          };
        }
        acc[show.podcaster_email].items.push({
          label: show.show_title,
          value: `${show.show_id}`,
          podcaster_id: show.podcaster_id,
        });
        return acc;
      }, {});

      podcastShowsOptions.value = Object.values(groupedShows);
    };

    const fetchPodcastShows = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/podcast-shows", {
          headers: { Authorization: `Bearer ${token}` },
        });

        const labelCounts = {};

        allShowsList.value = response.data.map((obj) => {
          if (!labelCounts[obj.show_title]) {
            labelCounts[obj.show_title] = 0; // Initialize count
          }
          labelCounts[obj.show_title] += 1;

          // Append the count to the label if it's a duplicate
          const newLabel =
            labelCounts[obj.show_title] > 1
              ? `${obj.show_title}_${labelCounts[obj.show_title] - 1}`
              : obj.show_title;

          return { ...obj, show_title: newLabel };
        });

        getGroupedShows(allShowsList.value);

        if (form.value.sellerGroup)
          checkIfGroupConnectedPodcaster(form.value.sellerGroup);
      } catch (error) {
        console.error("Error fetching podcast shows:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const confirmRSSConsentBeforeSubmit = () => {
      if (
        form.value.contentType === "rss_feed" &&
        (initialRSSUrl.value !== form.value.rssFeedUrl ||
          !form.value.consent_approved)
      ) {
        confirm.require({
          message:
            'To monetize your content on our platform, we request your permission to place ads on your RSS feed. By clicking "I Agree" below, you confirm that you allow JamX to monetize your content in the programmatic marketplace.',
          header: "Attention",
          icon: "pi pi-check-square",
          acceptLabel: "I Agree",
          rejectLabel: "Deny",
          accept: () => {
            form.value.consent_approved = true;
            submitForm();
          },
          reject: async () => {
            form.value.consent_approved = false;
            await submitForm();
            toast.add({
              severity: "error",
              summary: "Rejected",
              detail: "You have rejected the consent agreement.",
              life: 5000,
            });
          },
        });
      } else {
        submitForm();
      }
    };

    const confirmCPMHighBeforeSubmit = () => {
      if (
        form.value.contractType === "cpm" &&
        initialContractValue.value < 2 &&
        form.value.contractValue >= 2
      ) {
        confirm.require({
          message:
            "You have increased the CPM to over $2. Please confirm your agreement with this change.",
          header: "Attention",
          icon: "pi pi-exclamation-circle",
          acceptLabel: "Confirm",
          rejectLabel: "Cancel",
          accept: () => {
            setTimeout(() => {
              confirmRSSConsentBeforeSubmit();
            }, 300);
          },
        });
      } else {
        confirmRSSConsentBeforeSubmit();
      }
    };

    const fetchPlaylists = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/playlists", {
          headers: { Authorization: `Bearer ${token}`, partnerid: partnerId },
        });
        playlistsOptions.value = response.data.playlists.filter(
          (item) => item.status == "ready"
        );
      } catch (error) {
        // Handle errors, e.g., show an error message
        console.error("Error fetching playlists:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchCountryList = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name,cca2"
        );
        if (response.data) {
          countryList.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchSubSeller = async () => {
      try {
        store.commit("setLoading", true);
        if (!sellerId) return;

        const response = await axios.get(API_BASE_URL + "/seller/" + sellerId, {
          headers: {
            Authorization: `Bearer ${token}`,
            partnerid: partnerId,
          },
        });
        if (response.data.success) {
          const seller = response.data.seller;

          let categoriesObject = [];
          let siteCategoriesObject = [];

          if (seller.categories?.length && seller.categories !== "null") {
            categoriesObject = JSON.parse(seller.categories);
          }
          if (
            seller.site_categories?.length &&
            seller.site_categories !== "null"
          ) {
            siteCategoriesObject = JSON.parse(seller.site_categories);
          }

          form.value.geo_bl = seller.geo_bl?.length
            ? seller.geo_bl.toUpperCase().split(",")
            : [];
          form.value.geo_wl = seller.geo_wl?.length
            ? seller.geo_wl.toUpperCase().split(",")
            : [];
          form.value.name = seller.name;
          form.value.email = seller.email;
          form.value.domain = seller.domain;
          form.value.categories = categoriesObject;

          form.value.contractType = seller.contract_type;

          initialContractValue.value =
            seller.contract_type == "cpm" ? seller.contract_value : 0;
          form.value.contractValue =
            seller.contract_type == "cpm"
              ? seller.contract_value
              : seller.contract_value * 100;
          form.value.contentType = seller.content_type;
          form.value.rssFeedUrl = seller.rss_feed;
          form.value.playlists = seller.playlist_id;
          form.value.sellerGroup = seller.group_id;
          form.value.custom_slogan_text = seller.custom_slogan_text;
          form.value.custom_slogan_link = seller.custom_slogan_link;
          form.value.consent_approved = seller.consent_approved;
          form.value.selectedShows = seller.show_ids_content
            ? seller.show_ids_content.split(",")
            : [];

          initialRSSUrl.value = seller.rss_feed;
          form.value.tier = seller.tier;
          form.value.site_categories = siteCategoriesObject;
          if (seller.cpm_per_geo?.length)
            form.value.cpm_per_geo = JSON.parse(seller.cpm_per_geo);
        }
      } catch (error) {
        console.error("Error fetching sub-seller:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const onContractTypeChange = () => {
      // Reset the contract value when the contract type changes
      form.value.contractValue = 0;
    };

    const contactValidate = computed(() => {
      return (
        form.value.contractType === "revshare" ||
        (form.value.contractType === "cpm" &&
          form.value.contractValue &&
          form.value.contractValue > 0)
      );
    });

    const validateEmail = () => {
      const re =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      emailValid.value = re.test(String(form.value.email).toLowerCase());
    };

    const validateDomain = () => {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/;
      domainValid.value = re.test(String(form.value.domain).toLowerCase());
    };

    const validateContractDetails = () => {
      if (form.value.contractType === "cpm") {
        return (
          (!form.value.cpm_per_geo.length ||
            form.value.cpm_per_geo.every(
              (item) => item.geo?.length && item.cpm >= 0
            )) &&
          form.value.contractValue >= 0
        );
      } else if (form.value.contractType === "revshare") {
        return true;
      }
      return false;
    };

    const validateRssFeed = (urlStr) => {
      let url;

      try {
        url = new URL(urlStr);
      } catch (_) {
        return false;
      }

      return url.protocol === "http:" || url.protocol === "https:";
    };

    const validatePassword = () => {
      passwordValid.value = !(
        !form.value.password ||
        form.value.password.length < 8 ||
        !(
          /[a-z]/.test(form.value.password) && /[A-Z]/.test(form.value.password)
        )
      );
    };

    const isSelectable = (node) => {
      return !node.children;
    };

    const submitForm = async () => {
      submitted.value = true;
      validateEmail();
      validateDomain();

      if (!sellerId) validatePassword();
      else passwordValid.value = true;

      rssFeedUrlValid.value = true;
      if (form.value.contentType == "rss_feed") {
        rssFeedUrlValid.value = validateRssFeed(form.value.rssFeedUrl);
      }

      if (form.value.contentType === "shows") {
        form.value.show_ids_content = form.value.selectedShows.join(",");
      }

      if (
        form.value.name &&
        domainValid.value &&
        (emailValid.value || form.value.sellerGroup) &&
        (passwordValid.value || form.value.sellerGroup)
      ) {
        if (
          !rssFeedUrlValid.value ||
          ((!validateContractDetails() || !form.value.sellerGroup) &&
            !store.getters.isGroupAdminLoggedIn)
        )
          return;
        try {
          store.commit("setLoading", true);
          const response = await axios.post(
            API_BASE_URL + "/add-seller",
            form.value,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                partnerid: partnerId,
              },
            }
          );

          if (response.data?.success) {
            // Clear form after successful submission
            form.value = {
              name: "",
              email: "",
              domain: "",
              categories: [],
              contractType: "",
              contractValue: "",
              contentType: "",
              rssFeedUrl: "",
              password: "",
              sellerGroup: null,
              custom_slogan_link: "",
              custom_slogan_text: "",
              consent_approved: false,
              site_categories: [],
              tier: "3",
              cpm_per_geo: [],
            };

            submitted.value = false;

            toast.add({
              severity: "success",
              summary: "Success",
              detail: `Seller ${sellerId ? "updated" : "added"} successfully!`,
              life: 4000,
            });
            if (response.data?.autoPending) {
              toast.add({
                severity: "warn",
                summary: "Status Changed",
                detail: "Seller status has been changed to Pending.",
                life: 5000,
              });

              console.log("Resposne", response.data);
            }
            router.go(-1);
            // Show a success message, or redirect to another page, etc.
          } else {
            // Handle unsuccessful submission
            toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 4000,
            });
          }
        } catch (error) {
          // Handle errors
          toast.add({
            severity: "error",
            summary: "Error",
            detail: response.data.message,
            life: 4000,
          });
          console.log(error);
        } finally {
          store.commit("setLoading", false);
        }
      }
    };

    const fetchCategories = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/categories", {
          headers: { Authorization: `Bearer ${token}` },
        });
        categories.value = response.data;
      } catch (error) {
        // Handle errors, e.g., show an error message
        console.log("Error during categories fetch", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchGroups = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(API_BASE_URL + "/seller-groups", {
          headers: { Authorization: `Bearer ${token}`, partnerid: partnerId },
        });

        sellerGroups.value = response.data.sellerGroups.map((group) => {
          return {
            label: group.name,
            value: group.id,
            connected_podcaster_id: group.connected_podcaster_id,
          };
        });
        await fetchPodcastShows();
        fetchSubSeller();
      } catch (error) {
        // Handle errors, e.g., show an error message
      } finally {
        store.commit("setLoading", false);
      }
    };

    onMounted(fetchPlaylists);
    onMounted(fetchCategories);
    onMounted(fetchGroups);
    onMounted(fetchCountryList);

    watch(() => form.value.sellerGroup, checkIfGroupConnectedPodcaster);

    return {
      form,
      store,
      router,
      categories,
      submitForm,
      contractTypes,
      submitted,
      emailValid,
      domainValid,
      contractValueValid,
      onContractTypeChange,
      contentTypes,
      rssFeedUrlValid,
      validateRssFeed,
      isSelectable,
      searchText,
      contactValidate,
      sellerGroups,
      validatePassword,
      contactValueLabel,
      passwordValid,
      sellerId,
      playlistsOptions,
      confirmRSSConsentBeforeSubmit,
      confirmCPMHighBeforeSubmit,
      countryList,
      podcastShowsOptions,
      isAllShowsSelected,
      toggleAllShows,
      tierOptions,
      removeGeo,
      validateContractDetails,
    };
  },
};
</script>
