<template>
  <h2 class="mt-0" style="color: var(--text-color)">Custom Report</h2>
  <div class="flex flex-column gap-4">
    <Card>
      <template #title>
        <div class="flex justify-content-between align-items-center">
          <div class="text-xl">Saved Reports</div>
          <div class="flex gap-2">
            <Button
              icon="pi pi-save"
              label="Save Current"
              @click="showSaveDialog = true"
              class="p-button-secondary"
              :disabled="!isValid"
            />
            <Dropdown
              v-model="selectedSavedReport"
              :options="savedReports"
              optionLabel="name"
              placeholder="Load Saved Report"
              class="w-15rem"
              @change="loadSavedReport"
            >
              <template #option="slotProps">
                <div class="flex align-items-center justify-content-between">
                  <div>{{ slotProps.option.name }}</div>
                  <div class="ml-2" @click="dropdownDeleteOption = true">
                    <i class="pi pi-trash text-pink-500"></i>
                  </div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        <div class="text-xl">Filters</div>
      </template>
      <template #content>
        <div class="grid">
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="date-range">Date Range</label>
            <div class="flex flex-column gap-2">
              <Dropdown
                v-model="selectedDatePreset"
                :options="datePresets"
                optionLabel="label"
                placeholder="Select Date Range"
                class="w-full"
                @change="handleDatePresetChange"
              />
              <Calendar
                v-if="selectedDatePreset?.value === 'custom'"
                v-model="dateRange"
                selectionMode="range"
                :showIcon="true"
                inputId="date-range"
              />
            </div>
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="partners-list">Select Partners</label>
            <MultiSelect
              inputId="partners-list"
              v-model="selectedPartners"
              :options="memberList.partners"
              optionLabel="name"
              optionValue="id"
              placeholder="Select Partners"
              display="chip"
              :disabled="selectedAccountOwner"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="groups-list">Select Groups</label>
            <MultiSelect
              inputId="groups-list"
              v-model="selectedGroups"
              :options="memberList.groups"
              optionLabel="name"
              optionValue="id"
              placeholder="Select Groups"
              display="chip"
              filter
              :disabled="selectedAccountOwner"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="groups-list">Exclude Groups</label>
            <MultiSelect
              inputId="exclude-groups-list"
              v-model="excludeGroups"
              :options="memberList.groups"
              optionLabel="name"
              optionValue="id"
              placeholder="Exclude Groups"
              display="chip"
              filter
              :disabled="selectedAccountOwner"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="sellers-list">Select Sellers</label>
            <MultiSelect
              inputId="sellers-list"
              v-model="selectedSellers"
              :options="memberList.sellers"
              :optionLabel="(option) => `[${option.id}] ${option.name}`"
              optionValue="id"
              placeholder="Select Sellers"
              display="chip"
              filter
              :disabled="selectedAccountOwner"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="sellers-list">Exclude Sellers</label>
            <MultiSelect
              inputId="exclude-sellers-list"
              v-model="excludeSellers"
              :options="memberList.sellers"
              :optionLabel="(option) => `[${option.id}] ${option.name}`"
              optionValue="id"
              placeholder="Exclude Sellers"
              display="chip"
              filter
              :disabled="selectedAccountOwner"
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="tags-list">Select Tags</label>
            <MultiSelect
              inputId="tags-list"
              v-model="selectedTags"
              :options="tags"
              optionLabel="name"
              optionValue="id"
              placeholder="Select Tags"
              display="chip"
              filter
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="tags-list">Exclude Tags</label>
            <MultiSelect
              inputId="exclude-tags-list"
              v-model="excludeTags"
              :options="tags"
              optionLabel="name"
              optionValue="id"
              placeholder="Exclude Tags"
              display="chip"
              filter
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="tag-partners-list">Select Tag Partners</label>
            <MultiSelect
              inputId="tag-partners-list"
              v-model="selectedTagPartners"
              :options="tagPartners"
              optionLabel="name"
              optionValue="id"
              placeholder="Select Tag Partners"
              display="chip"
              filter
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="geos">GEOs</label>
            <MultiSelect
              inputId="geos"
              v-model="selectedGeos"
              :options="countryList"
              optionLabel="name.common"
              optionValue="cca2"
              placeholder="Select Countries"
              display="chip"
              filter
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <label for="acc_owners">Group Owners</label>
            <Dropdown
              input-id="acc_owners"
              v-model="selectedAccountOwner"
              :options="accountOwners"
              option-label="name"
              option-value="id"
              placeholder="Select Owner"
              show-clear
            />
          </div>
          <div class="flex flex-column gap-1 col-12 md:col-6 p-fluid">
            <div class="flex align-items-center gap-2">
              <Checkbox
                v-model="compareUntilNow"
                :binary="true"
                inputId="compare-until-now"
              />
              <label for="compare-until-now">Compare Until Current Hour</label>
            </div>
          </div>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        <div class="text-xl">Dimensions</div>
      </template>
      <template #content>
        <div class="grid">
          <div
            v-for="dimension in dimensions"
            :key="dimension.name"
            class="flex align-items-center gap-1 col-6 sm:col-4 md:col-3"
          >
            <Checkbox
              :inputId="dimension.name"
              v-model="selectedDimensions"
              :value="dimension"
              @change="validateSelections"
            />
            <label :for="dimension.name">{{ dimension.name }}</label>
          </div>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        <div class="flex justify-content-between align-items-center">
          <div class="text-xl">Metrics</div>
          <Button
            label="Select All"
            icon="pi pi-check"
            link
            size="small"
            iconPos="right"
            @click="selectAllMetrics"
          />
        </div>
      </template>
      <template #content>
        <div class="grid">
          <div
            v-for="metric in availableMetrics"
            :key="metric.name"
            class="flex align-items-center gap-1 col-6 sm:col-4 md:col-3"
          >
            <Checkbox
              :inputId="metric.name"
              v-model="selectedMetrics"
              :value="metric"
              @change="validateSelections"
            />
            <label :for="metric.name">{{ metric.name }}</label>
          </div>
        </div>
      </template>
    </Card>
    <div v-if="validationWarnings.length > 0">
      <Message
        v-for="warning in validationWarnings"
        :key="warning"
        :closable="false"
        severity="warn"
      >
        {{ warning }}
      </Message>
    </div>
    <div class="flex justify-content-end">
      <Button
        @click="runReport"
        :disabled="isReportLoading || !isValid"
        :loading="isReportLoading"
        :label="runReportButtonLabel"
        class="p-button-primary"
      />
    </div>
    <div id="report_table">
      <div class="report-results" v-if="reportData">
        <DataTable
          :value="reportData.tableData"
          :columns="reportColumns"
          :paginator="true"
          :rows="10"
          :rowsPerPageOptions="[5, 10, 20, 50]"
          class="p-datatable-sm"
          :global-filter-fields="reportColumns.map((col) => col.field)"
          :sortOrder="-1"
          :sortField="reportColumns[0]?.field"
          :filters="globalFilter"
          :loading="isReportLoading"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row gap-2 justify-content-between"
            >
              <InputGroup class="md:max-w-18rem">
                <InputGroupAddon>
                  <i class="pi pi-search"></i>
                </InputGroupAddon>
                <InputText
                  v-model="globalFilter.global.value"
                  placeholder="Keyword Search"
                />
              </InputGroup>
              <Button
                icon="pi pi-download"
                @click="exportCSV"
                label="Download CSV"
                class="p-button-secondary"
              />
            </div>
          </template>
          <Column
            v-for="col in reportColumns"
            :key="col.field"
            :field="col.field"
            :header="col.header"
            sortable
          />
        </DataTable>
      </div>
    </div>
    <Dialog v-model:visible="showSaveDialog" header="Save Report" :modal="true">
      <div class="flex flex-column gap-2">
        <label for="report-name">Report Name</label>
        <InputText id="report-name" v-model="newReportName" class="w-20rem" />
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          @click="showSaveDialog = false"
          class="p-button-text"
          :disabled="isSaving"
        />
        <Button
          label="Save"
          icon="pi pi-check"
          @click="saveReport"
          :disabled="isSaving"
          :loading="isSaving"
          autofocus
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useToast } from "primevue/usetoast";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import axios from "axios";
import { API_BASE_URL } from "../consts";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import { FilterMatchMode } from "primevue/api";
import { useRouter, useRoute } from "vue-router";
import ProgressSpinner from "primevue/progressspinner";
import Message from "primevue/message";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import { useStore } from "vuex";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import moment from "moment";
import { useConfirm } from "primevue/useconfirm";

export default {
  name: "CustomReportPage",
  components: {
    Calendar,
    MultiSelect,
    Checkbox,
    Button,
    ProgressSpinner,
    DataTable,
    Column,
    Card,
    InputText,
    Message,
    InputGroup,
    InputGroupAddon,
    Dialog,
    Dropdown,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const confirm = useConfirm();

    const dateRange = ref(null);
    const selectedPartners = ref([]);
    const selectedGroups = ref([]);
    const selectedSellers = ref([]);
    const excludeGroups = ref([]);
    const excludeSellers = ref([]);
    const selectedDimensions = ref([]);
    const selectedMetrics = ref([]);
    const isReportLoading = ref(false);
    const selectedAccountOwner = ref(null);
    const accountOwners = ref([]);

    const selectedGeos = ref([]);
    const selectedTags = ref([]);
    const selectedTagPartners = ref([]);
    const excludeTags = ref([]);
    const tags = ref([]);
    const tagPartners = ref([]);
    const compareUntilNow = ref(false);

    const reportData = ref(null);
    const visibleDatasets = ref([]);
    const validationWarnings = ref([]);
    const memberList = ref({ partners: [], groups: [], sellers: [] });
    const countryList = ref([]);

    const selectedDatePreset = ref(null);

    const dropdownDeleteOption = ref(false);

    const datePresets = [
      { label: "Today", value: "today" },
      { label: "Yesterday", value: "yesterday" },
      { label: "Last 7 Days", value: "last7days" },
      { label: "Last 30 Days", value: "last30days" },
      { label: "Month to Date", value: "monthToDate" },
      { label: "Last Month", value: "lastMonth" },
      { label: "Custom", value: "custom" },
    ];

    const globalFilter = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const dimensions = [
      { name: "Date", isTagRelated: false, field: "date" },
      { name: "Month", isTagRelated: false, field: "month" },

      { name: "Hour", isTagRelated: false, field: "hour" },
      { name: "Partner ID", isTagRelated: false, field: "partner_id" },
      { name: "Partner Name", isTagRelated: false, field: "partner_name" },
      { name: "Group ID", isTagRelated: false, field: "group_id" },
      { name: "Group Name", isTagRelated: false, field: "group_name" },
      { name: "Seller ID", isTagRelated: false, field: "seller_id" },
      { name: "Seller Name", isTagRelated: false, field: "seller_name" },
      { name: "Geo", isTagRelated: false, field: "geo" },
      { name: "Domain", isTagRelated: false, field: "domain" },
      { name: "Tag ID", isTagRelated: true, field: "tag_id" },
      { name: "Tag Name", isTagRelated: true, field: "tag_name" },
      { name: "Tag Partner", isTagRelated: true, field: "tag_partner" },
      { name: "Show Title", isTagRelated: false, field: "show_title" },
      { name: "Device Type", isTagRelated: false, field: "device_type" },
      { name: "Group Owner", isTagRelated: false, field: "group_owner" },
    ];

    const metrics = [
      { name: "Qualified Loads", isLoadRelated: true, field: "player_loads" },
      { name: "Total Loads", isLoadRelated: true, field: "total_player_loads" },
      { name: "Ad Impressions", isLoadRelated: false, field: "ad_impressions" },
      { name: "Revenue", isLoadRelated: false, field: "revenue" },
      { name: "Cost", isLoadRelated: false, field: "cost" },
      { name: "Profit", isLoadRelated: false, field: "profit" },
      { name: "Profit Margin", isLoadRelated: false, field: "profit_margin" },
      { name: "RPM", isLoadRelated: false, field: "rpm" },
      { name: "CPM", isLoadRelated: false, field: "cpm" },
      { name: "RPL", isLoadRelated: true, field: "rpl" },
      { name: "CPL", isLoadRelated: true, field: "cpl" },
      { name: "Fill Rate", isLoadRelated: false, field: "fill_rate" },
      { name: "Ad Play Rate", isLoadRelated: true, field: "ad_play_rate" },
      { name: "Tag Requests", isTagRelated: false, field: "tag_requests" },
      { name: "Req per Load", isLoadRelated: true, field: "req_per_load" },
      {
        name: "Loads difference",
        isLoadRelated: true,
        field: "loads_difference",
      },
      {
        name: "Impressions difference",
        isLoadRelated: false,
        field: "impressions_difference",
      },
    ];

    const showSaveDialog = ref(false);
    const newReportName = ref("");
    const savedReports = ref([]);
    const selectedSavedReport = ref(null);

    const selectAllMetrics = () => {
      selectedMetrics.value = availableMetrics.value;
    };

    const handleDatePresetChange = () => {
      if (!selectedDatePreset.value) return;

      const now = moment();
      const offset = now.utcOffset();

      const today = moment()
        .utcOffset(offset)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

      const endOfDay = {
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      };

      switch (selectedDatePreset.value.value) {
        case "today":
          dateRange.value = [
            today.clone().toDate(),
            today.clone().set(endOfDay).toDate(),
          ];
          break;

        case "yesterday":
          dateRange.value = [
            today.clone().subtract(1, "days").toDate(),
            today.clone().subtract(1, "days").set(endOfDay).toDate(),
          ];
          break;

        case "last7days":
          dateRange.value = [
            today.clone().subtract(6, "days").toDate(),
            today.clone().set(endOfDay).toDate(),
          ];
          break;

        case "last30days":
          dateRange.value = [
            today.clone().subtract(29, "days").toDate(),
            today.clone().set(endOfDay).toDate(),
          ];
          break;

        case "monthToDate":
          dateRange.value = [
            today.clone().startOf("month").toDate(),
            today.clone().set(endOfDay).toDate(),
          ];
          break;

        case "lastMonth":
          const lastMonth = today.clone().subtract(1, "month");
          dateRange.value = [
            lastMonth.clone().startOf("month").toDate(),
            lastMonth.clone().endOf("month").set(endOfDay).toDate(),
          ];
          break;

        case "custom":
          break;
      }
    };

    const fetchSavedReports = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(
          `${API_BASE_URL}/custom-reports/saved-reports`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        savedReports.value = response.data.reports;
      } catch (error) {
        console.error("Error fetching saved reports:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch saved reports",
          life: 3000,
        });
      }
    };

    const isSaving = ref(false);

    const deleteSavedReport = async () => {
      store.commit("setLoading", true);
      try {
        const token = localStorage.getItem("adminToken");
        const result = await axios.delete(
          `${API_BASE_URL}/custom-reports/saved-reports/${selectedSavedReport.value.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (result.data.success) {
          await fetchSavedReports();
          toast.add({
            severity: "info",
            summary: "Deleted",
            detail: "Saved report has been deleted",
            life: 3000,
          });
        }
      } catch (error) {
        console.error("Error deleting saved report:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete saved report",
          life: 3000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const saveReport = async () => {
      if (!newReportName.value.trim()) {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please enter a report name",
          life: 3000,
        });
        return;
      }

      isSaving.value = true;

      console.log("isSaving", isSaving.value);

      try {
        const token = localStorage.getItem("adminToken");
        const config = {
          dateRange: dateRange.value,
          datePreset: selectedDatePreset.value,
          selectedPartners: selectedPartners.value,
          selectedGroups: selectedGroups.value,
          selectedSellers: selectedSellers.value,
          excludeGroups: excludeGroups.value,
          excludeSellers: excludeSellers.value,
          selectedDimensions: selectedDimensions.value,
          selectedMetrics: selectedMetrics.value,
          selectedGeos: selectedGeos.value,
          selectedTags: selectedTags.value,
          selectedTagPartners: selectedTagPartners.value,
          excludeTags: excludeTags.value,
          compareUntilNow: compareUntilNow.value,
          selectedAccountOwner: selectedAccountOwner.value,
        };

        await axios.post(
          `${API_BASE_URL}/custom-reports/saved-reports`,
          {
            name: newReportName.value,
            config: config,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Report saved successfully",
          life: 3000,
        });

        showSaveDialog.value = false;
        newReportName.value = "";
        fetchSavedReports();
      } catch (error) {
        console.error("Error saving report:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to save report",
          life: 3000,
        });
      } finally {
        isSaving.value = false;
      }
    };

    const loadSavedReport = async (e) => {
      if (!selectedSavedReport.value) return;
      if (dropdownDeleteOption.value) {
        dropdownDeleteOption.value = false;
        confirm.require({
          message: `Are you sure to delete saved report "${e.value.name}"?`,
          header: "Confirm Delete",
          icon: "pi pi-exclamation-triangle",
          accept: async () => {
            await deleteSavedReport();
            selectedSavedReport.value = null;
          },
          reject: () => {
            console.log("Cancel delete saved report");
            selectedSavedReport.value = null;
          },
        });
        return;
      }

      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(
          `${API_BASE_URL}/custom-reports/saved-reports/${selectedSavedReport.value.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const config = JSON.parse(response.data.report.config);
        config.dateRange = config.dateRange.map((date) => new Date(date));

        dateRange.value = config.dateRange;
        selectedDatePreset.value = config.datePreset;
        handleDatePresetChange();

        selectedPartners.value = config.selectedPartners || [];
        selectedGroups.value = config.selectedGroups || [];
        selectedSellers.value = config.selectedSellers || [];
        excludeGroups.value = config.excludeGroups || [];
        excludeSellers.value = config.excludeSellers || [];
        selectedDimensions.value = config.selectedDimensions || [];
        selectedMetrics.value = config.selectedMetrics || [];
        selectedGeos.value = Array.isArray(config.selectedGeos)
          ? config.selectedGeos
          : config.selectedGeos
              .split(",")
              .map((geo) => geo.trim())
              .filter((geo) => geo.length > 0);
        selectedTags.value = config.selectedTags || [];
        selectedTagPartners.value = config.selectedTagPartners || [];
        excludeTags.value = config.excludeTags || [];
        compareUntilNow.value = config.compareUntilNow;
        selectedAccountOwner.value = config.selectedAccountOwner;

        console.log("load saved report", dateRange.value);

        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Report loaded successfully",
          life: 3000,
        });
      } catch (error) {
        console.error("Error loading report:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to load report",
          life: 3000,
        });
      }
    };

    const reportColumns = computed(() => {
      return [
        ...selectedDimensions.value.map((d) => ({
          field: d.field,
          header: d.name,
        })),
        ...selectedMetrics.value.map((m) => ({
          field: m.field,
          header: m.name,
        })),
      ];
    });

    const isTagRelatedDimensionSelected = computed(() => {
      return selectedDimensions.value.some((d) => d.isTagRelated);
    });

    const availableMetrics = computed(() => {
      if (isTagRelatedDimensionSelected.value) {
        return metrics;
      } else {
        return metrics.filter((m) => !m.isTagRelated);
      }
    });

    const isValid = computed(() => {
      return (
        validationWarnings.value.length === 0 &&
        dateRange.value &&
        selectedDimensions.value.length > 0 &&
        selectedMetrics.value.length > 0
      );
    });

    const runReportButtonLabel = computed(() => {
      if (!dateRange.value) return "Select Date Range";
      if (selectedDimensions.value.length === 0) return "Select Dimensions";
      if (selectedMetrics.value.length === 0) return "Select Metrics";
      if (!isValid.value) return "Resolve Warnings";
      return "Run Report";
    });

    const validateSelections = () => {
      validationWarnings.value = [];

      const tagRelatedDimensions = selectedDimensions.value.filter(
        (d) => d.isTagRelated
      );
      const loadRelatedMetrics = selectedMetrics.value.filter(
        (m) => m.isLoadRelated
      );

      if (tagRelatedDimensions.length > 0 && loadRelatedMetrics.length > 0) {
        validationWarnings.value.push(
          "Tag-related dimensions cannot be chosen with load-related metrics."
        );
      }

      /*
      if (
        !isTagRelatedDimensionSelected.value &&
        selectedMetrics.value.some((m) => m.name === "Tag Requests")
      ) {
        validationWarnings.value.push(
          "Tag Requests metric requires a tag-related dimension."
        );
      }*/

      // Remove any metrics that are no longer valid
      selectedMetrics.value = selectedMetrics.value.filter((m) =>
        availableMetrics.value.some((am) => am.name === m.name)
      );
    };

    const fetchTags = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(`${API_BASE_URL}/tags`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data?.tags) {
          tags.value = response.data.tags;
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    const fetchTagPartners = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const response = await axios.get(`${API_BASE_URL}/tag-group`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data) {
          tagPartners.value = response.data;
        }
      } catch (error) {
        console.error("Error fetching tag partners:", error);
      }
    };

    const fetchMemberList = async () => {
      try {
        const token = localStorage.getItem("adminToken");
        const { data } = await axios.get(API_BASE_URL + "/member-list/all", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (data.success) {
          const { partners, groups, sellers } = data;
          memberList.value = { partners, groups, sellers };
          console.log("memberList", memberList.value);
          parseQueryParams();
        }
      } catch (error) {
        console.error("Error fetching member list:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch member list",
          life: 3000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchAccountManagers = async () => {
      store.commit("setLoading", true);
      try {
        const response = await axios.get(`${API_BASE_URL}/account-managers`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
          },
        });

        if (response.data.success) {
          accountOwners.value = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching account managers:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Error fetching group account managers",
          life: 4000,
        });
      } finally {
        store.commit("setLoading", false);
      }
    };

    const fetchCountryList = async () => {
      try {
        store.commit("setLoading", true);
        const response = await axios.get(
          "https://restcountries.com/v3.1/all?fields=name,cca2"
        );
        if (response.data) {
          countryList.value = response.data.map((item) => ({
            ...item,
            cca2: item.cca2.toLowerCase(),
          }));
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      } finally {
        store.commit("setLoading", false);
      }
    };

    const convertToGMT0 = (date) => {
      // Get the timezone offset in minutes and convert to milliseconds
      const timezoneOffsetMilliseconds = date.getTimezoneOffset() * 60 * 1000;

      // Adjust the date by adding the offset
      const gmt0Time = new Date(date.getTime() - timezoneOffsetMilliseconds);

      return gmt0Time;
    };

    const formatDate = (date) => {
      let adjusted = new Date(date);
      return convertToGMT0(adjusted);
    };

    const runReport = async () => {
      if (!isValid.value) {
        toast.add({
          severity: "error",
          summary: "Validation Error",
          detail: "Please resolve all warnings before running the report",
          life: 3000,
        });
        return;
      }

      isReportLoading.value = true;
      try {
        const response = await axios.post(
          `${API_BASE_URL}/admin/custom-report`,
          {
            dateRange: {
              start: formatDate(dateRange.value[0]),
              end: formatDate(dateRange.value[1]),
            },
            compareUntilNow: compareUntilNow.value,
            partners:
              selectedPartners.value.length > 0 ? selectedPartners.value : [],
            groups: selectedGroups.value.length > 0 ? selectedGroups.value : [],
            sellers:
              selectedSellers.value.length > 0 ? selectedSellers.value : [],
            exclude_groups:
              excludeGroups.value.length > 0 ? excludeGroups.value : [],
            exclude_sellers:
              excludeSellers.value.length > 0 ? excludeSellers.value : [],
            dimensions: selectedDimensions.value.map((d) => d.name),
            metrics: selectedMetrics.value.map((m) => m.name),
            geos: selectedGeos.value,
            tags: selectedTags.value,
            tagPartners: selectedTagPartners.value,
            exclude_tags: excludeTags.value,
            account_owner: selectedAccountOwner.value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
            },
          }
        );

        const formattedData = formatData(response.data);
        reportData.value = formattedData;
        visibleDatasets.value = reportData.value.chartData.map(
          (_, index) => index
        );
        toast.add({
          severity: "success",
          summary: "Report Generated",
          detail: "Your report has been generated successfully",
          life: 3000,
        });
      } catch (error) {
        console.error("Error fetching report data:", error);
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to generate report",
          life: 3000,
        });
      } finally {
        isReportLoading.value = false;
        setTimeout(() => {
          document
            .getElementById("report_table")
            .scrollIntoView({ behavior: "smooth" });
        }, 100);
      }
    };

    const formatData = (data) => {
      data.tableData = data.tableData.map((item) => {
        if (item.profit_margin)
          item.profit_margin = item.profit_margin.toFixed(2) * 100 + "%";
        if (item.fill_rate)
          item.fill_rate = (item.fill_rate * 100).toFixed(2) + "%";
        if (item.ad_play_rate)
          item.ad_play_rate = (item.ad_play_rate * 100).toFixed(2) + "%";
        if (item.revenue) item.revenue = `$${item.revenue.toFixed(2)}`;
        if (item.cost) item.cost = `$${item.cost.toFixed(2)}`;
        if (item.rpm) item.rpm = `$${item.rpm.toFixed(2)}`;
        if (item.cpm) item.cpm = `$${item.cpm.toFixed(2)}`;
        if (item.rpl) item.rpl = `$${item.rpl.toFixed(2)}`;
        if (item.cpl) item.cpl = `$${item.cpl.toFixed(2)}`;
        if (item.profit) item.profit = `$${item.profit.toFixed(2)}`;
        if (item.req_per_load)
          item.req_per_load = `${item.req_per_load.toFixed(2)}`;
        return item;
      });
      return data;
    };

    const exportCSV = () => {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(reportData.value.tableData);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "csv",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "report");
      });
    };

    const saveAsExcelFile = (buffer, fileName) => {
      import("file-saver").then((module) => {
        if (module && module.default) {
          let EXCEL_TYPE =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          let EXCEL_EXTENSION = ".csv";
          const data = new Blob([buffer], {
            type: EXCEL_TYPE,
          });
          module.default.saveAs(
            data,
            fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
          );
        }
      });
    };

    const updateQueryParams = () => {
      router.replace({
        query: {
          dateRange: dateRange.value ? JSON.stringify(dateRange.value) : null,
          datePreset: selectedDatePreset.value?.value || null,
          partners: selectedPartners.value.join(","),
          groups: selectedGroups.value.join(","),
          sellers: selectedSellers.value.join(","),
          exclude_groups: excludeGroups.value.join(","),
          exclude_sellers: excludeSellers.value.join(","),
          dimensions: selectedDimensions.value.map((d) => d.name).join(","),
          metrics: selectedMetrics.value.map((m) => m.name).join(","),
          geos: selectedGeos.value.join(","),
          tags: selectedTags.value.join(","),
          tagPartners: selectedTagPartners.value.join(","),
          exclude_tags: excludeTags.value.join(","),
          compareUntilNow: compareUntilNow.value.toString(),
          account_owner: selectedAccountOwner.value,
        },
      });
    };

    // New function to parse URL query parameters
    const parseQueryParams = () => {
      const {
        dateRange: dateRangeParam,
        datePreset: datePresetParam,
        partners: partnersParam,
        groups: groupsParam,
        sellers: sellersParam,
        exclude_groups: groupsExcludeParam,
        exclude_sellers: sellersExcludeParam,
        dimensions: dimensionsParam,
        metrics: metricsParam,
        geos: geosParam,
        tags: tagsParam,
        tagPartners: tagPartnersParam,
        exclude_tags: tagsExcludeParam,
        compareUntilNow: compareUntilNowParam,
        account_owner: accountOwnerParam,
      } = route.query;

      if (datePresetParam) {
        selectedDatePreset.value = datePresets.find(
          (preset) => preset.value === datePresetParam
        );
      }

      if (dateRangeParam) {
        const parsedDateRange = JSON.parse(dateRangeParam);
        dateRange.value = [
          new Date(parsedDateRange[0]),
          new Date(parsedDateRange[1]),
        ];
        if (!selectedDatePreset.value) {
          selectedDatePreset.value = datePresets.find(
            (preset) => preset.value === "custom"
          );
        }
      }

      if (dateRangeParam) {
        const parsedDateRange = JSON.parse(dateRangeParam);
        dateRange.value = [
          new Date(parsedDateRange[0]),
          new Date(parsedDateRange[1]),
        ];
      }
      if (partnersParam)
        selectedPartners.value = partnersParam.split(",").map((id) => id);
      if (groupsParam)
        selectedGroups.value = groupsParam.split(",").map((id) => id * 1);

      if (sellersParam)
        selectedSellers.value = sellersParam.split(",").map((id) => id);

      if (geosParam)
        selectedGeos.value = geosParam
          .split(",")
          .map((geo) => geo.trim())
          .filter((geo) => geo.length > 0);
      if (tagsParam) selectedTags.value = tagsParam.split(",").map((id) => id);
      if (tagPartnersParam)
        selectedTagPartners.value = tagPartnersParam
          .split(",")
          .map((id) => id * 1);
      if (groupsExcludeParam)
        excludeGroups.value = groupsExcludeParam.split(",").map((id) => id * 1);

      if (sellersExcludeParam)
        excludeSellers.value = sellersExcludeParam.split(",").map((id) => id);

      if (tagsExcludeParam)
        excludeTags.value = tagsExcludeParam.split(",").map((id) => id);

      if (dimensionsParam) {
        selectedDimensions.value = dimensionsParam.split(",").map(
          (d) =>
            dimensions.find((dim) => dim.name === d) || {
              name: d,
              isTagRelated: false,
            }
        );
      }
      if (metricsParam) {
        selectedMetrics.value = metricsParam.split(",").map(
          (m) =>
            metrics.find((met) => met.name === m) || {
              name: m,
              isLoadRelated: false,
            }
        );
      }

      if (compareUntilNowParam) {
        compareUntilNow.value = compareUntilNowParam === "true";
      }

      if (accountOwnerParam) {
        selectedAccountOwner.value = accountOwnerParam;
      }
    };

    // Watch for changes in form fields and update URL
    watch(
      [
        dateRange,
        selectedDatePreset,
        selectedPartners,
        selectedGroups,
        selectedSellers,
        excludeGroups,
        excludeSellers,
        selectedDimensions,
        selectedMetrics,
        selectedGeos,
        selectedTags,
        selectedTagPartners,
        excludeTags,
        compareUntilNow,
        selectedAccountOwner,
      ],
      () => {
        updateQueryParams();
      }
    );

    onMounted(() => {
      store.commit("setLoading", true);
      fetchMemberList();
      fetchTags();
      fetchTagPartners();
      fetchSavedReports();
      fetchCountryList();
      fetchAccountManagers();
    });

    return {
      isReportLoading,

      dateRange,
      selectedPartners,
      selectedGroups,
      selectedSellers,
      excludeGroups,
      excludeSellers,
      selectedDimensions,
      selectedMetrics,

      reportData,
      dimensions,
      metrics,
      memberList,
      availableMetrics,
      runReport,

      validateSelections,
      validationWarnings,
      isValid,
      runReportButtonLabel,
      reportColumns,
      globalFilter,
      exportCSV,
      selectedGeos,
      selectedTagPartners,
      selectedTags,
      excludeTags,
      tags,
      tagPartners,
      compareUntilNow,
      showSaveDialog,
      newReportName,
      savedReports,
      selectedSavedReport,
      saveReport,
      loadSavedReport,
      datePresets,
      selectedDatePreset,
      handleDatePresetChange,
      isSaving,
      dropdownDeleteOption,
      countryList,
      selectAllMetrics,
      accountOwners,
      selectedAccountOwner,
    };
  },
};
</script>

<style scoped>
.custom-report-page {
  margin: 0 auto;
  padding: 0px 5px;
}

.full-page-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.chart-container {
  height: 400px;
}

.validation-warnings {
  padding: 1rem;
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 0.25rem;
  color: #856404;
}

.validation-warnings ul {
  margin: 0;
  padding-left: 1.25rem;
}

h1 {
  color: var(--text-color);
}

.p-card {
  padding: 0px 10px;
}
</style>
