<template>
  <Dialog
    v-model:visible="modalVisible"
    modal
    :closable="false"
    :header="`${isEdit ? 'Edit' : 'Add'} Manager`"
    style="width: 22rem"
  >
    <span class="p-text-secondary block mb-5"
      >Provide the manager details.</span
    >
    <form v-on:submit.prevent="$emit('saveAccountManager', accountManagerData)">
      <div class="flex align-items-center gap-3 mb-3">
        <label for="username" class="font-semibold w-6rem">Name *</label>
        <InputText
          id="username"
          class="flex-auto"
          autocomplete="off"
          v-model="accountManagerData.name"
          required
          aria-errormessage="Name is required"
        />
      </div>
      <div class="flex align-items-center gap-3 mb-5">
        <label for="email" class="font-semibold w-6rem">Email</label>
        <InputText
          id="email"
          class="flex-auto"
          autocomplete="off"
          v-model="accountManagerData.email"
        />
      </div>
      <div class="flex justify-content-end gap-2">
        <Button
          type="button"
          label="Cancel"
          severity="secondary"
          @click="$emit('closeModal')"
        ></Button>
        <Button label="Save" type="submit"></Button>
      </div>
    </form>
  </Dialog>
</template>

<script setup>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import { ref } from "vue";
import { watch } from "vue";

const props = defineProps(["visible", "accountManager", "isEdit"]);
const emit = defineEmits(["saveAccountManager", "closeModal"]);

const accountManagerData = ref({
  name: "",
  email: "",
});
const modalVisible = ref(false);

watch(
  () => props.accountManager,
  () => {
    accountManagerData.value = { ...props.accountManager };
  }
);
watch(
  () => props.visible,
  () => {
    modalVisible.value = props.visible;
  }
);
</script>
